import CoreAjax             from "dashboard/dist/Core/Ajax";
import CoreAuth             from "dashboard/dist/Core/Auth";
import Href                 from "dashboard/dist/Core/Href";
import Access               from "Utils/Access";
import { Auth }             from "Utils/API";

// Types
import {
    AUTH_DATA, AUTH_COURSE, AUTH_ASSIGN, AUTH_CURRENT_USER,
} from "Utils/Types";



/**
 * Sets the Auth Data
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setData(data) {
    return { type : AUTH_DATA, data };
}

/**
 * Sets the Auth Course
 * @param {Object} data
 * @returns {{type: String, data: Object}}
 */
export function setCourse(data) {
    return { type : AUTH_COURSE, data };
}

/**
 * Sets the Current Credential
 * @param {Object} credential
 * @returns {{type: String, credential: Object}}
 */
export function setCurrentUser(credential) {
    return { type : AUTH_CURRENT_USER, credential };
}



/**
 * Fetches the Assign data
 * @param {Number} strechID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchAssign(strechID) {
    return async (dispatch) => {
        const data = await Auth.getAssignData({ strechID });
        dispatch({ type : AUTH_ASSIGN, data });
    };
}



/**
 * Validates the Email
 * @param {Object} data
 * @returns {(dispatch: Function) => Void}
 */
export function validateEmail(data) {
    return () => Auth.validateEmail(data);
}

/**
 * Validates the Password
 * @param {Object} data
 * @returns {(dispatch: Function) => Void}
 */
export function validatePassword(data) {
    return () => Auth.validatePassword(data);
}

/**
 * Requests a Password Reset
 * @param {Object} data
 * @returns {() => Void}
 */
export function requestReset(data) {
    return () => Auth.requestReset(data);
}

/**
 * Resets a Password
 * @param {Object} data
 * @returns {() => Void}
 */
export function resetPassword(data) {
    return () => Auth.resetPassword(data);
}

/**
 * Saves the User Data
 * @param {Object} data
 * @returns {() => Void}
 */
export function saveData(data) {
    return () => Auth.saveData(data);
}

/**
 * Saves the User Courses
 * @param {Object} data
 * @returns {() => Void}
 */
export function saveCourses(data) {
    return () => Auth.saveCourses(data);
}

/**
 * Saves the User Assign
 * @param {Number} strechID
 * @param {Object} options
 * @returns {() => Void}
 */
export function saveAssign(strechID, options) {
    return () => Auth.saveAssign({ strechID, ...options });
}



/**
 * Logins as an User
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function loginAs(credentialID) {
    return () => Auth.loginAs({ credentialID });
}

/**
 * Logouts the User
 * @returns {() => Void}
 */
export function logout() {
    return async () => {
        const user = CoreAuth.getUser();
        CoreAjax.abort();

        if (user.loggedAsUser) {
            const result = await Auth.logoutAs();
            if (result.credentialID) {
                switch (result.access) {
                case Access.STUDENT:
                    Href.goto("STUDENTS", result.credentialID);
                    break;
                case Access.COACH:
                    Href.goto("COACHES", result.credentialID);
                    break;
                case Access.SUPPORT:
                case Access.ADMIN:
                case Access.OWNERS:
                    Href.goto("SETTINGS", "ADMINS");
                    break;
                default:
                }
            }
        } else {
            Auth.logout();
            CoreAuth.unsetToken();
        }
    };
}
