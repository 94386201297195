const data = {

    TITLE                           : "Protagonista de Cambio",
    LANGUAGES                       : { "es" : "Español", "en" : "English" },
    MODES                           : { 1 : "Presencial", 2 : "Virtual" },
    TIMES                           : { 1 : "Mañana", 2 : "Tarde", 3 : "Noche" },

    SUBSCRIPTIONS                   : { 0 : "Sin subscripción", 1 : "Subscripto", 2 : "Dado de baja", 3 : "Rebotado" },
    SUBSCRIPTIONS_COLORS            : { 0 : "gray", 1 : "green", 2 : "yellow", 3 : "red" },

    TYPES                           : { 1 : "Curso", 2 : "Taller", 3 : "Charla" },
    TYPES_BUTTONS                   : { 1 : "Ver curso", 2 : "Ver taller", 3 : "Ver charla" },
    TYPES_ENROLL                    : { 1 : "Inscribirme al curso", 2 : "Inscribirme al taller", 3 : "Inscribirme a la charla" },



    // General
    GENERAL_ACCEPT                  : "Aceptar",
    GENERAL_ACCESS                  : "Acceso",
    GENERAL_ADD                     : "Agregar",
    GENERAL_ADD_FIELD               : "Agregar campo",
    GENERAL_ADRRESS                 : "Dirección",
    GENERAL_ALL                     : "Todos",
    GENERAL_ALL_FEM                 : "Todas",
    GENERAL_AMOUNT                  : "Cantidad",
    GENERAL_AND                     : "y",
    GENERAL_ANY                     : "Cualquiera",
    GENERAL_APPROVE                 : "Aprobar",
    GENERAL_ASSIGN                  : "Asignar",
    GENERAL_ATTACH                  : "Adjuntar",
    GENERAL_BIRTHDATE               : "Fecha de nacimiento",
    GENERAL_CANCEL                  : "Cancelar",
    GENERAL_CATEGORY                : "Categoría",
    GENERAL_CELLPHONE               : "Celular",
    GENERAL_CLOSE                   : "Cerrar",
    GENERAL_CODE                    : "Código",
    GENERAL_COMPLETE                : "Completar",
    GENERAL_COPY_URL                : "Copiar Url",
    GENERAL_CREATE                  : "Crear",
    GENERAL_CREATED                 : "Creado el",
    GENERAL_CREATED_FEM             : "Creada el",
    GENERAL_CREATED_TIME            : "Fecha de creación",
    GENERAL_CUSTOM                  : "Personalizado ...",
    GENERAL_DATE                    : "Fecha",
    GENERAL_DATE_TIME               : "{0} de {1} a {2}",
    GENERAL_DATE_TIME_ALL           : "Todos los {0} de {1} a {2}",
    GENERAL_DATE_TIME_WEEK          : "Los {0} de {1} a {2} cada {3} semanas",
    GENERAL_DATE_PERIOD             : "De {0} a {1}",
    GENERAL_DAY                     : "Día",
    GENERAL_DELETE                  : "Eliminar",
    GENERAL_DESCRIPTION             : "Descripción",
    GENERAL_DOWNLOAD                : "Descargar",
    GENERAL_EDIT                    : "Editar",
    GENERAL_EMAIL                   : "Email",
    GENERAL_END                     : "Cierre",
    GENERAL_ENROLL                  : "Inscribir",
    GENERAL_EXPORT                  : "Exportar",
    GENERAL_FEE                     : "Honorarios",
    GENERAL_FEEDBACK                : "Feedback",
    GENERAL_FILE                    : "Archivo",
    GENERAL_FILTER                  : "Filtrar",
    GENERAL_FROM                    : "Desde",
    GENERAL_FROM_DATE               : "Fecha desde",
    GENERAL_FROM_HOUR               : "Hora de inicio",
    GENERAL_HOUR                    : "Hora",
    GENERAL_ID                      : "ID",
    GENERAL_IMPORT                  : "Importar",
    GENERAL_INACTIVE                : "Inactivo",
    GENERAL_LANGUAGE                : "Idioma",
    GENERAL_LAST_REPLY              : "Última respuesta",
    GENERAL_LEVEL                   : "Nivel",
    GENERAL_LOADING                 : "Cargando",
    GENERAL_LOGIN_AS                : "Ingresar como usuario",
    GENERAL_LOGIN_TIME              : "Último ingreso",
    GENERAL_LOGOUT                  : "Salir",
    GENERAL_LOGGED_OUT              : "La sesión ha terminado",
    GENERAL_MESSAGE                 : "Mensaje",
    GENERAL_MODE                    : "Modalidad",
    GENERAL_MODES                   : "Modalidades",
    GENERAL_NAME                    : "Nombre",
    GENERAL_NEXT                    : "Siguiente",
    GENERAL_NO                      : "No",
    GENERAL_NOBODY                  : "Nadie",
    GENERAL_NONE                    : "Ninguno",
    GENERAL_NONE_FEM                : "Ninguna",
    GENERAL_NUMBER                  : "Número",
    GENERAL_OBSERVATIONS            : "Observaciones",
    GENERAL_OR                      : "ó",
    GENERAL_OTHER                   : "Otro",
    GENERAL_PAID                    : "Pagada",
    GENERAL_PAGE                    : "Página {0} de {1}",
    GENERAL_PAGE_OF                 : "{0}-{1} de {2}",
    GENERAL_PERCENT                 : "Porcentaje",
    GENERAL_PERIOD                  : "Periodo",
    GENERAL_POSITION                : "Posición",
    GENERAL_PREV                    : "Anterior",
    GENERAL_PRICE                   : "Precio",
    GENERAL_PRINT                   : "Imprimir",
    GENERAL_PROGRAM                 : "Programar",
    GENERAL_QUOTA                   : "Capacidad",
    GENERAL_REASON                  : "Motivo",
    GENERAL_REJECT                  : "Rechazar",
    GENERAL_REMOVE_FILTER           : "Quitar filtro",
    GENERAL_REQUEST                 : "Solicitar",
    GENERAL_REPORT                  : "Reportar",
    GENERAL_ROWS_PER_PAGE           : "Filas a mostrar: ",
    GENERAL_SAVE                    : "Guardar",
    GENERAL_SEARCH                  : "Buscar",
    GENERAL_SEARCH_ONE              : "Buscar ...",
    GENERAL_SEARCH_RESULTS          : "Resultados",
    GENERAL_SELECT                  : "Seleccionar",
    GENERAL_SELECT_FILE             : "Seleccionar archivo ...",
    GENERAL_SEND                    : "Enviar",
    GENERAL_SEND_AT                 : "Enviar el",
    GENERAL_SEND_CAMPAIGN           : "Campaña",
    GENERAL_SEND_EMAIL              : "Enviar email",
    GENERAL_SEND_MESSAGE            : "Enviar mensaje",
    GENERAL_SENT                    : "Enviado",
    GENERAL_SENT_AT                 : "Enviado el",
    GENERAL_SENT_AT_FEM             : "Enviada el",
    GENERAL_SUBJECT                 : "Asunto",
    GENERAL_SUBSCRIPTION            : "Subscripción",
    GENERAL_START                   : "Inicio",
    GENERAL_STATUS                  : "Estado",
    GENERAL_STREAM_LINK             : "Vínculo del stream",
    GENERAL_STREAM_ZOOM             : "Ir a Zoom",
    GENERAL_STREAM_PASSWORD         : "Contraseña del stream",
    GENERAL_STUDENT_TITLE           : "Nombre para el alumno",
    GENERAL_STUDENT_TITLE_HELP      : "Nombre que se muestra al alumno en todos los lugares del sistema y en los mails",
    GENERAL_TIME                    : "Tiempo",
    GENERAL_TO                      : "Hasta",
    GENERAL_TO_DATE                 : "Fecha hasta",
    GENERAL_TO_HOUR                 : "Hora de cierre",
    GENERAL_TOTAL                   : "Total",
    GENERAL_TYPE                    : "Tipo",
    GENERAL_URL                     : "Url",
    GENERAL_VIEW                    : "Ver",
    GENERAL_YES                     : "Si",
    GENERAL_YES_NO                  : { 0 : "No", 1 : "Si" },
    GENERAL_YES_BLANK               : { 0 : "", 1 : "Si" },
    GENERAL_X_OF_Y                  : "{0} de {1}",

    GENERAL_ERROR                   : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",
    GENERAL_ERROR_PATH              : "Ruta incorrecta.",
    GENERAL_ERROR_STUDENT_TITLE     : "Debe indicar el nombre a mostrar al alumno.",
    GENERAL_ERROR_FILE              : "El archivo indicado no existe.",
    GENERAL_ERROR_PDF               : "El archivo indicado debe ser un PDF.",
    GENERAL_ERROR_IMAGE             : "La imagen indicado no existe.",
    GENERAL_ERROR_DATE              : "La fecha es incorrecta.",
    GENERAL_ERROR_DATE_PERIOD       : "La fecha hasta debe ser posterior a la fecha desde.",
    GENERAL_ERROR_HOUR              : "La hora indicada es incorrecta.",
    GENERAL_ERROR_HOUR_PERIOD       : "La hora de cierre debe ser posterior a la hora de inicio.",
    GENERAL_ERROR_WEEKDAY           : "El día de la semana es incorrecto.",
    GENERAL_ERROR_PRICE             : "El precio indicado es incorrecto.",
    GENERAL_ERROR_ACCESS            : "El nivel de accesso indicado es incorrecto.",
    GENERAL_ERROR_POSITION          : "La posición debe ser un número mayor a cero.",
    GENERAL_ERROR_STATUS            : "El estado indicado es incorrecto.",
    GENERAL_ERROR_MODES             : "Debe indicar por lo menos una modalidad.",
    GENERAL_ERROR_MODE              : "El modo indicado es incorrecto.",
    GENERAL_ERROR_TIMES             : "Debe indicar por lo menos un horario.",
    GENERAL_ERROR_TIME              : "El horario indicado es incorrecto.",
    GENERAL_ERROR_STREAM_LINK       : "Debe indicar el vínculo al stream.",
    GENERAL_ERROR_STREAM_ZOOM       : "La url de zoom es inválida.",



    // Auth
    AUTH_TITLE                      : "Bienvenido",
    AUTH_EMAIL_HELP                 : "Por favor escribí tu email para ingresar o para crear una cuenta.",
    AUTH_PASSWORD_HELP              : "Por favor escribí tu contraseña para continuar. Si no la recordás podes hacer click en Recordar Contraseña.",
    AUTH_PASSWORD_RECOVER           : "¿Recuperar contraseña?",
    AUTH_RESET_HELP                 : "Se envió un email a <b>{0}</b> con un código para copiar y pegar más abajo, o con un link que podes hacer click. Luego te pedimos que completes tu nueva contraseña.",
    AUTH_COURSE_HELP                : "Por favor indica los cursos que completaste.",
    AUTH_INSCRIPTION_HELP           : "Por favor completa los datos a continuación para poder completar tu inscripción.",
    AUTH_GO_BACK                    : "¿Volver?",
    AUTH_ERROR_SPAM                 : "Has intentado demasiadas veces, espera un segundo y vuelve a probar.",
    AUTH_ERROR_EMAIL_EMPTY          : "Por favor, ingresa tu email.",
    AUTH_ERROR_EMAIL_INVALID        : "El email ingresado es incorrecto.",
    AUTH_ERROR_EMAIL_INACTIVE       : "El email ingresado está inactivo.",
    AUTH_ERROR_DISABLED             : "El ingreso esta deshabilitado.",
    AUTH_ERROR_LOGIN                : "Lamentablemente no puede ingresar al sistema.",
    AUTH_ERROR_PASSWORD_EMPTY       : "Por favor, completa la contraseña.",
    AUTH_ERROR_PASSWORD_INCORRECT   : "La contraseña indicada es incorrecta.",
    AUTH_ERROR_PASSWORD_CONFIRM     : "Por favor, confirma la contraseña.",
    AUTH_ERROR_PASSWORD_MATCH       : "Las contraseñas no coinciden.",
    AUTH_ERROR_REQUEST              : "No se pudo enviar el email, por favor intenta con otro diferente.",
    AUTH_ERROR_CODE_EMPTY           : "Por favor, completa el código.",
    AUTH_ERROR_CODE_INVALID         : "El código indicado es incorrecto o ha expirado.",
    AUTH_SUCCESS                    : "Bienvenido a Protagonista de Cambio",

    // Auth: Assignme
    ASSIGNME_TITLE                  : "Asignarme",
    ASSIGNME_HELP                   : "Para poder inscribirte para ser coach de <b>{0}</b>, primero necesitamos que completes tu email.",
    ASSIGNME_HELP2                  : "Por favor indica las <b>modalidades</b> y los <b>horarios</b> donde estas disponibles para tener conversaciones de coaching para el tramo <b>{0}</b>.",
    ASSIGNME_ERROR_EXISTS           : "No puede inscribirse como coach al tramo porque no existe o no esta activo.",
    ASSIGNME_ERROR_PERIOD           : "No puede inscribirse coomo coach al tramo <b>{0}</b> porque ya terminó el periodo de inscripción.",
    ASSIGNME_ERROR_PERMISSION       : "No tienes permiso para inscribirte al tramo indicado.",
    ASSIGNME_SUCCESS                : "Muchas gracias por inscribirte como coach a<br/><b>{0}</b>.<br/>Cuando inicie el tramo te enviaremos un email con tus alumnos asignados.",




    // Student: Welcome
    WELCOME_NAME                    : "Bienvenida",
    WELCOME_TEXT                    : "Bienvenido a Protagonista de Cambio. Desde aquí podrás ver tus cursos y consultarnos cualquier duda que tengas.",
    WELCOME_ENROLL_INFO             : "Consultá los cursos disponibles",
    WELCOME_TICKET_INFO             : "Consultas",

    // Student: Home
    HOME_NAME                      : "Inicio",
    HOME_COMPLETE_DATA             : "Por favor haga click aquí para completar sus datos",
    HOME_ANSWER_STRECH             : "Haga click aquí para completar la encuesta del curso",
    HOME_ACTIVITIES                : "Próximas actividades",
    HOME_STRECH                    : "<b>{0}</b><br/>De {1} a {2}",
    HOME_CLASS                     : "<b>Clase de {0}</b><br/>{1} de {2} a {3}",
    HOME_CHAT                      : "<b>Charla de {0}</b><br/>{1} de {2} a {3}",
    HOME_MEETING                   : "<b>Reunión del grupo {0}</b><br/>{1}",
    HOME_CONVERSATION              : "<b>Conversación con {0}</b><br/>{1}",
    HOME_NONE_ACTIVITIES           : "No tiene próximas actividades",

    // Student: Calendar
    CALENDAR_NAME                   : "Calendario",
    CALENDAR_TODAY                  : "Hoy",
    CALENDAR_GOTO                   : "Ir al día",
    CALENDAR_GOTO_TEXT              : "Indique un día",
    CALENDAR_STRECH                 : "<b>{0}:</b> De {1} a {2}",
    CALENDAR_CLASS                  : "<b>Clase de {0}:</b> {1} de {2} a {3}",
    CALENDAR_CHAT                   : "<b>Charla de {0}:</b> {1} de {2} a {3}",
    CALENDAR_MEETING                : "<b>Reunión del grupo {0}:</b> a las {1}",
    CALENDAR_CONVERSATION           : "<b>Conversación con {0}:</b> a las {1}",

    // Student: Enroll
    ENROLL_NAME                     : "Inscribirme",
    ENROLL_TEXT                     : "¿Deseas enviar una solicitud para inscribirte a<br/><b>{0}</b>?<br />Luego de aceptar, nos contactaremos a la brevedad para completar tu inscripción.",
    ENROLL_LINK_PAGOS_TEXT          : "Link de Pago",
    ENROLL_LINK_PAGOS1              : "http://mpago.la/1ZES3Wa",
    ENROLL_TEXT_PAGOS               : " Y luego de pagar en un máximo de 24hs hábiles te va a llegar un mail con los datos del zoom y acceso a la clase",
    ENROLL_TEXT_FREE                : "¿Deseas inscribirte a <b>{0}</b>?",
    ENROLL_TEXT_OPTIONS             : "Por favor indicanos en que modalidades y horarios estas disponible para tener conversaciones de coaching.",
    ENROLL_CHAT_TITLE               : "Inscribirme a la charla informativa",
    ENROLL_CHAT_TEXT                : "Indicanos a que charlas informativas de <b>{0}</b> te deseas inscribir:",
    ENROLL_INFO_TITLE               : "Inscripción creada",
    ENROLL_INFO_TEXT                : "Muchas gracias por inscribirte a<br/><b>{0}</b>.<br/>Nos contactaremos a la brevedad para completar tu inscripción.",
    ENROLL_INFO_TEXT_FREE           : "Muchas gracias por inscribirte a<br/><b>{0}</b>.<br/>En unos minutos te enviaremos más información.",
    ENROLL_INFO_TEXT_ADD_CHAT       : "Muchas gracias por inscribirte a la charla informativa de <b>{0}</b>.<br/>En unos minutos te enviaremos más información.",
    ENROLL_INFO_TEXT_DEL_CHAT       : "Muchas gracias por avisar que no asistiras a la charla informativa de <b>{0}</b>.",
    ENROLL_CANCEL_TITLE             : "Cancelar inscripción",
    ENROLL_CANCEL_TEXT              : "¿Realmente desea cancelar la inscripción a<br/><b>{0}</b>?",
    ENROLL_SCHEDULE                 : "Horario",
    ENROLL_SCHEDULE_OPTIONS         : { 1 : "Mañana", 2 : "Tarde", 3 : "Noche" },
    ENROLL_FOR                      : "Para",
    ENROLL_FOR_OPTIONS              : { 1 : "Para Alumnos", 2 : "Para Coaches" },
    ENROLL_COURSE_DATES             : "<b>Cursada</b> del {0} al {1}",
    ENROLL_MEETING_DATE             : "<b>Encuentro</b> el {0} de {1} a {2}",
    ENROLL_MEETING_DATES            : "<b>Encuentro</b> de {0} a {1}",
    ENROLL_INSCRIPTION_DATES        : "<b>Inscripción</b> del {0} al {1}",
    ENROLL_CHAT_DATES               : "<b>Charla informativa</b> el {0} de {1} a {2}",
    ENROLL_CHAT_SELECT              : "Charla del {0} de {1} a {2}",
    ENROLL_COACHES_SINGULAR         : "<b>Facilitador</b> {0}",
    ENROLL_COACHES_PLURAL           : "<b>Facilitadores</b> {0}",
    ENROLL_ENCOUNTERS_SINGULAR      : "1 encuentro",
    ENROLL_ENCOUNTERS_PLURAL        : "{0} encuentros",
    ENROLL_CONVERSATIONS_SINGULAR   : "1 conversación de coaching individual",
    ENROLL_CONVERSATIONS_PLURAL     : "{0} conversaciones de coaching individual",
    ENROLL_MEETINGS_SINGULAR        : "1 encuentro en sub grupos con otro coach facilitador",
    ENROLL_MEETINGS_PLURAL          : "{0} encuentros en sub grupos con otro coach facilitador",
    ENROLL_GUIDES                   : "lecturas y guías de trabajo personal a través de nuestro sistema",
    ENROLL_INCLUDES                 : "<b>Incluye</b> {0}.",
    ENROLL_PRICE                    : "<b>Inversión</b> de {0}.",
    ENROLL_PRICE_PROMOTION          : " y abonando hasta el <b>{0}</b> inclusive de <b>$ {1}</b>",
    ENROLL_PRICE_NORMAL_SINGULAR    : "{0} cuota de <b>$ {1}</b>",
    ENROLL_PRICE_NORMAL_PLURAL      : "{0} cuotas de <b>$ {1}</b>",
    ENROLL_PRICE_PROMOTION_SINGULAR : "{0} cuota de <b>$ {1}</b> hasta el <b>{2}</b> inclusive.",
    ENROLL_PRICE_PROMOTION_PLURAL   : "{0} cuotas de <b>$ {1}</b> hasta el <b>{2}</b> inclusive.",
    ENROLL_AVAILABLE_SINGULAR       : "¡Queda 1 solo lugar disponible!",
    ENROLL_AVAILABLE_PLURAL         : "¡Quedan {0} lugares disponibles!",
    ENROLL_NONE_AVAILABLE           : "No hay cursos disponibles para inscribirse.",
    ENROLL_NONE_FILTERED            : "No hay cursos disponibles para el filtro indicado.",
    ENROLL_STRECH_HELP              : "¡Gracias por solicitar inscribirte a <b>{0}</b>!<br/>Necesitamos ahora que completes tu email.",
    ENROLL_OPTIONS_HELP             : "¡Gracias por solicitar inscribirte a <b>{0}</b>!<br/>Necesitamos ahora que completes la modalidad y horarios en los que estas disponible para tener conversaciones de coaching.",
    ENROLL_CHAT_HELP                : "¡Gracias por solicitar inscribirte a la charla informativa de <b>{0}</b>!<br/>Necesitamos ahora que completes tu email.",
    ENROLL_BACK                     : "¿Seleccionar otro?",
    ENROLL_ERROR_EXISTS             : "No existe la inscripción indicada.",
    ENROLL_ERROR_ADMIN              : "No puedes inscribirte como un administrador. Puedes hacerlo con otra cuenta.",
    ENROLL_ERROR_COURSE             : "No se puede inscribir al curso indicado.",
    ENROLL_ERROR_DATES              : "La inscripción al curso ya terminó.",
    ENROLL_ERROR_COACH              : "Solo coaches puede inscribirse al curso.",
    ENROLL_ERROR_STUDENT_ACTIVE     : "No eres un alumno activo.",
    ENROLL_ERROR_STUDENT_DATA       : "Necesitas completar todos tus datos.",
    ENROLL_ERROR_CORRELATIVE        : "Necesitas primero hacer el curso <b>{0}</b>.",
    ENROLL_ERROR_ENROLLED           : "Ya estas inscripto en el curso.",
    ENROLL_ERROR_QUOTA              : "No hay capacidad disponible para el curso.",
    ENROLL_ERROR_CANCEL             : "No tienes permiso para cancelar la inscripción.",
    ENROLL_ERROR_GENERAL            : "Lamentablemente no puedes inscribirte a <b>{0}</b> porque {1}",
    ENROLL_ERROR_GENERAL_CHAT       : "Lamentablemente no puedes inscribirte a la charla informativa de <b>{0}</b> porque {1}",
    ENROLL_SUCCESS_ENROLL_CHAT      : "Se guardó tu inscripción a la charla informativa.",
    ENROLL_SUCCESS_ENROLL           : "La inscripción fue exitosa.",
    ENROLL_SUCCESS_CANCEL           : "Su inscripción se canceló correctamente.",




    // Dashboard
    DASHBOARD_NAME                  : "Inicio",
    DASHBOARD_STUDENTS              : "Últimos registros",
    DASHBOARD_INSCRIPTIONS          : "Solicitudes de inscripción",
    DASHBOARD_TICKETS               : "Nuevas consultas",
    DASHBOARD_LEADS                 : "Nuevos leads",
    DASHBOARD_INSCRIPTION           : "{0} en <b>{1}</b>",
    DASHBOARD_TICKET                : "<b>{0}</b> de {1}",
    DASHBOARD_LEAD                  : "{0} desde <b>{1}</b>",
    DASHBOARD_SHOW_ALL              : "Ver todos",

    // Courses
    COURSES_NAME                    : "Cursos",
    COURSES_SINGULAR                : "Curso",
    COURSES_MINE                    : "Mis cursos",
    COURSES_DETAILS_TITLE           : "Detalles del curso",
    COURSES_CREATE_TITLE            : "Crear curso",
    COURSES_EDIT_TITLE              : "Editar curso",
    COURSES_VIEW_TITLE              : "Ver curso",
    COURSES_VIEW_URL_TITLE          : "Ver url del curso",
    COURSES_DELETE_TITLE            : "Eliminar curso",
    COURSES_DELETE_TEXT             : "¿Realmente desea eliminar el curso <b>{0}</b>?",
    COURSES_NONE_AVAILABLE          : "No hay cursos disponibles.",
    COURSES_ITS_NAME                : "Nombre del curso",
    COURSES_ITS_URL                 : "Url del curso",
    COURSES_URL_HELP                : "Url usada para la inscripción",
    COURSES_CORRELATIVE             : "Curso correlativo",
    COURSES_CORRELATIVE_SHORT       : "Correlativo",
    COURSES_FILTER_IN_INSCRIPTION   : "¿Mostrar en el filtro en las inscripciones?",
    COURSES_REQUIRED_TO_BE_COACH    : "¿El alumno debe completar el curso para poder ser coach?",
    COURSES_COMPLETE_TO_COACH       : "¿El coach debe completar el curso para ser asignado como coach?",
    COURSES_NO_URL                  : "El curso <b>{0}</b> no tiene la url configurada. Puede hacer click en editar para configurarla.",
    COURSES_ERROR_EXISTS            : "No existe el curso indicado.",
    COURSES_ERROR_NAME              : "Debe indicar el nombre del curso.",
    COURSES_ERROR_URL_INVALID       : "La url debe contener solo letras a-z, números 1-9 y guiones.",
    COURSES_ERROR_URL_USED          : "La url indicada ya esta usada por otro curso.",
    COURSES_ERROR_CORRELATIVE       : "El curso correlativo no existe.",
    COURSES_ERROR_STATUS            : "Debe indicar el estado del curso.",
    COURSES_SUCCESS_CREATE          : "El curso se creó correctamente.",
    COURSES_SUCCESS_EDIT            : "El curso se editó correctamente.",
    COURSES_SUCCESS_DELETE          : "El curso se eliminó correctamente.",

    // Courses: Completed
    COURSES_COMPLETED_NAME          : "Cursos completados",
    COURSES_COMPLETED_SINGULAR      : "Curso completado",
    COURSES_COMPLETED_HELP          : "Indique los cursos completados por <b>{0}</b>:",
    COURSES_COMPLETED_ERROR_USER    : "No existe el usuario indicado.",
    COURSES_COMPLETED_ERROR_EMPTY   : "Debe indicar que cursos completó el usuario.",
    COURSES_COMPLETED_ERROR_COURSE  : "Uno de los cursos indicados no existe.",
    COURSES_COMPLETED_SUCCESS       : "Los cursos completados se editaron correctamente.",



    // Programs
    PROGRAMS_NAME                   : "Programas",
    PROGRAMS_SINGULAR               : "Programa",
    PROGRAMS_DETAILS_TITLE          : "Detalles del programa",
    PROGRAMS_CREATE_TITLE           : "Crear programa",
    PROGRAMS_EDIT_TITLE             : "Editar programa",
    PROGRAMS_VIEW_TITLE             : "Ver programa",
    PROGRAMS_DELETE_TITLE           : "Eliminar programa",
    PROGRAMS_DELETE_TEXT            : "¿Realmente desea eliminar el programa <b>{0}</b>?",
    PROGRAMS_NONE_AVAILABLE         : "No hay programas disponibles.",
    PROGRAMS_COURSE                 : "Curso del programa",
    PROGRAMS_TYPE                   : "Tipo de programa",
    PROGRAMS_ITS_NAME               : "Nombre del programa",
    PROGRAMS_FILE                   : "Archivo de bienvenida",
    PROGRAMS_IS_FREE                : "¿Es gratuito?",
    PROGRAMS_ONLY_COACHES           : "¿Disponible solo para coaches?",
    PROGRAMS_HAS_CHATS              : "¿Tiene charlas informativas?",
    PROGRAMS_HAS_CLASSES            : "¿Tiene clases?",
    PROGRAMS_HAS_MATERIALS          : "¿Tiene materiales?",
    PROGRAMS_HAS_GUIDES             : "¿Tiene guías?",
    PROGRAMS_HAS_GROUPS             : "¿Tiene grupos?",
    PROGRAMS_HAS_CONVERSATIONS      : "¿Tiene conversaciones?",
    PROGRAMS_HAS_POLL               : "¿Tiene encuesta final?",
    PROGRAMS_HAS_CERTIFICATE        : "¿Tiene certificado?",
    PROGRAMS_ERROR_EXISTS           : "No existe el programa indicado.",
    PROGRAMS_ERROR_TYPE_EMPTY       : "Debe indicar el tipo del programa.",
    PROGRAMS_ERROR_TYPE_INVALID     : "El tipo de programa indicado no existe.",
    PROGRAMS_ERROR_NAME             : "Debe indicar el nombre del programa.",
    PROGRAMS_ERROR_GUIDE            : "Debe indicar la guía 0 del programa.",
    PROGRAMS_ERROR_FILE             : "El archivo de bienvenida debe ser un PDF o imagen.",
    PROGRAMS_ERROR_STATUS           : "Debe indicar el estado del programa.",
    PROGRAMS_SUCCESS_CREATE         : "El programa se creó correctamente.",
    PROGRAMS_SUCCESS_EDIT           : "El programa se editó correctamente.",
    PROGRAMS_SUCCESS_DELETE         : "El programa se eliminó correctamente.",

    // Programs: Prices
    PRICES_NAME                     : "Precios",
    PRICES_SINGULAR                 : "Precio",
    PRICES_CREATE_TITLE             : "Crear precio",
    PRICES_EDIT_TITLE               : "Editar precio",
    PRICES_DELETE_TITLE             : "Eliminar precio",
    PRICES_DELETE_TEXT              : "¿Realmente desea eliminar el precio <b>{0}</b>?",
    PRICES_IMPORT_TITLE             : "Importar precios",
    PRICES_IMPORT_TEXT              : "¿Realmente desea importar todos los precios del programa?",
    PRICES_NONE_AVAILABLE           : "No hay precios disponibles.",
    PRICES_NEW_PRICE                : "Nuevo precio",
    PRICES_NEW_HELP                 : "Puede crear una precio a paritir de uno predefinado o crear uno nuevo.",
    PRICES_FREE                     : "Gratuito",
    PRICES_NAME_SINGULAR            : "{0} cuota de $ {1}",
    PRICES_NAME_PLURAL              : "{0} cuotas de $ {1}",
    PRICES_PROMOTION_SINGULAR       : "{0} cuota de $ {1} hasta el {2}",
    PRICES_PROMOTION_PLURAL         : "{0} cuotas de $ {1} hasta el {2}",
    PRICES_DUES                     : "Cantidad de cuotas",
    PRICES_DUES_SINGULAR            : "{0} cuota",
    PRICES_DUES_PLURAL              : "{0} cuotas",
    PRICES_PRICE                    : "Precio de la cuota",
    PRICES_SHOW                     : "Mostrar el precio a los alumnos",
    PRICES_SHOW_SHORT               : "Mostrar",
    PRICES_PROMOTION                : "Pronto pago",
    PRICES_PROMOTION_DATE           : "Hasta el {0}",
    PRICES_IS_PROMOTION             : "Es un precio pronto pago",
    PRICES_PROMOTION_END            : "Fecha de finalización del pronto pago",
    PRICES_PAYMENT_LINK             : "Link de MercadoPago",
    PRICES_ERROR_EXISTS             : "No existe el precio indicado.",
    PRICES_ERROR_USED               : "El precio indicado ya fue utilizado.",
    PRICES_ERROR_DUES_EMPTY         : "Debe indicar la cantidad de cuotas.",
    PRICES_ERROR_DUES_INVALID       : "La cantidad de cuotas debe ser un número mayor a 0.",
    PRICES_ERROR_PRICE_EMPTY        : "Debe indicar el precio de la cuota.",
    PRICES_ERROR_PRICE_INVALID      : "El precio de la cuota es incorrecto.",
    PRICES_ERROR_IS_PROMOTION       : "El precio puede ser pronto pago solo si es de 1 cuota y mostrado a los alumnos.",
    PRICES_ERROR_PROMOTION_EMPTY    : "Debe indicar la fecha de finalización del pronto pago.",
    PRICES_ERROR_PROMOTION_INVALID  : "La fecha de finalización del pronto pago es incorrecta.",
    PRICES_ERROR_PAYMENT_LINK       : "Debe indicar el link de MercadoPago.",
    PRICES_ERROR_STATUS             : "Debe indicar el estado del precio.",
    PRICES_ERROR_DATA               : "No hay precios para importar.",
    PRICES_ERROR_IMPORT             : "No hay nada nuevo para importar.",
    PRICES_SUCCESS_CREATE           : "El precio se creó correctamente.",
    PRICES_SUCCESS_EDIT             : "El precio se editó correctamente.",
    PRICES_SUCCESS_DELETE           : "El precio se eliminó correctamente.",
    PRICES_SUCCESS_IMPORT           : "Los precios se importaron correctamente.",

    // Programs: Chats
    CHATS_NAME                      : "Charlas informativas",
    CHATS_SINGULAR                  : "Charla informativa",
    CHATS_VIEW_TITLE                : "Ver charla informativa",
    CHATS_CREATE_TITLE              : "Crear charla informativa",
    CHATS_EDIT_TITLE                : "Editar charla informativa",
    CHATS_DELETE_TITLE              : "Eliminar charla informativa",
    CHATS_DELETE_TEXT               : "¿Realmente desea eliminar la charla informativa <b>{0}</b>?",
    CHATS_IMPORT_TITLE              : "Importar charlas informativas",
    CHATS_IMPORT_TEXT               : "¿Realmente desea importar todas las charlas informativas del programa?",
    CHATS_NONE_AVAILABLE            : "No hay charlas informativas disponibles.",
    CHATS_NEW_CLASS                 : "Nueva charla informativa",
    CHATS_NEW_HELP                  : "Puede crear una charla informativa a paritir de una predefinada o crear una nueva.",
    CHATS_ITS_NAME                  : "Nombre de la charla",
    CHATS_DESCRIPTION               : "Descripción de la charla",
    CHATS_VIDEO                     : "Video de la charla",
    CHATS_DATE                      : "Fecha de la charla",
    CHATS_SCHEDULE                  : "Horario de la charla",
    CHATS_COACH                     : "Coach asignado",
    CHATS_COACH_SEARCH              : "O buscar otro coach",
    CHATS_ERROR_EXISTS              : "No existe la charla indicada.",
    CHATS_ERROR_NAME                : "Debe indicar el nombre de la charla.",
    CHATS_ERROR_DATE                : "Debe indicar la fecha de la charla.",
    CHATS_ERROR_FROM_HOUR           : "Debe indicar la hora de inicio de la charla.",
    CHATS_ERROR_TO_HOUR             : "Debe indicar la hora de cierre de la charla.",
    CHATS_ERROR_COACH               : "Debe indicar el coach de la charla.",
    CHATS_ERROR_SCHEDULE            : "Debe indicar el horario de la charla.",
    CHATS_ERROR_STATUS              : "Debe indicar el estado de la charla.",
    CHATS_ERROR_SCHEDULES           : "Primero debe completar los horarios del tramo.",
    CHATS_ERROR_DATA                : "No hay charlas para importar.",
    CHATS_ERROR_IMPORT              : "No hay nada nuevo para importar.",
    CHATS_SUCCESS_CREATE            : "La charla se creó correctamente.",
    CHATS_SUCCESS_EDIT              : "La charla se editó correctamente.",
    CHATS_SUCCESS_DELETE            : "La charla se eliminó correctamente.",
    CHATS_SUCCESS_IMPORT            : "Las charlas se importaron correctamente.",

    // Programs: Classes
    CLASSES_NAME                    : "Clases",
    CLASSES_SINGULAR                : "Clase",
    CLASSES_VIEW_TITLE              : "Ver clase",
    CLASSES_CREATE_TITLE            : "Crear clase",
    CLASSES_EDIT_TITLE              : "Editar clase",
    CLASSES_DATE_TITLE              : "Cambiar día/horario",
    CLASSES_FEEDBACK                : "Feedback de la clase",
    CLASSES_FEEDBACK_TITLE          : "Editar feedback",
    CLASSES_FEEDBACK_TEXT           : "Escriba el feedback de la clase.",
    CLASSES_DELETE_TITLE            : "Eliminar clase",
    CLASSES_DELETE_TEXT             : "¿Realmente desea eliminar la clase <b>{0}</b>?",
    CLASSES_IMPORT_TITLE            : "Importar clases",
    CLASSES_IMPORT_TEXT             : "¿Realmente desea importar todas las clases del programa? Indique la fecha inicial:",
    CLASSES_NONE_AVAILABLE          : "No hay clases disponibles.",
    CLASSES_NONE                    : "Sin clase",
    CLASSES_WELCOME                 : "Bienvenida",
    CLASSES_NEW_CLASS               : "Nueva clase",
    CLASSES_NEW_HELP                : "Puede crear una clase a paritir de una predefinada o crear una nueva.",
    CLASSES_ITS_NAME                : "Nombre de la clase",
    CLASSES_DESCRIPTION             : "Descripción de la clase",
    CLASSES_VIDEOS                  : "Videos de la clase",
    CLASSES_ADD_VIDEO               : "Agregar video",
    CLASSES_DATE                    : "Fecha de la clase",
    CLASSES_SCHEDULE                : "Horario de la clase",
    CLASSES_COACH                   : "Facilitador",
    CLASSES_COACH_SEARCH            : "O buscar otro coach",
    CLASSES_ERROR_EXISTS            : "No existe la clase indicada.",
    CLASSES_ERROR_EDIT_DATE         : "No tiene permiso para editar la fecha de la clase indicada.",
    CLASSES_ERROR_EDIT_FEEDBACK     : "No tiene permiso para editar el feedback de la clase indicada.",
    CLASSES_ERROR_NAME              : "Debe indicar el nombre de la clase.",
    CLASSES_ERROR_DATE_EMPTY        : "Debe indicar la fecha de la clase.",
    CLASSES_ERROR_DATE_TODAY        : "La fecha de la clase debe ser posterior al día actual.",
    CLASSES_ERROR_COACH             : "Debe indicar el coach de la clase.",
    CLASSES_ERROR_SCHEDULE          : "Debe indicar el horario de la clase.",
    CLASSES_ERROR_FROM_HOUR         : "Debe indicar la hora de inicio de la clase.",
    CLASSES_ERROR_TO_HOUR           : "Debe indicar la hora de cierre de la clase.",
    CLASSES_ERROR_STATUS            : "Debe indicar el estado de la clase.",
    CLASSES_ERROR_SCHEDULES         : "Primero debe completar los horarios del tramo.",
    CLASSES_ERROR_DATA              : "No hay clases para importar.",
    CLASSES_ERROR_IMPORT_TIME       : "Debe indicar la fecha para importar.",
    CLASSES_ERROR_IMPORT            : "No hay nada nuevo para importar.",
    CLASSES_SUCCESS_CREATE          : "La clase se creó correctamente.",
    CLASSES_SUCCESS_EDIT            : "La clase se editó correctamente.",
    CLASSES_SUCCESS_EDIT_DATE       : "La fecha de la clase se editó correctamente.",
    CLASSES_SUCCESS_EDIT_FEEDBACK   : "El feedback de la clase se editó correctamente.",
    CLASSES_SUCCESS_DELETE          : "La clase se eliminó correctamente.",
    CLASSES_SUCCESS_IMPORT          : "Las clases se importaron correctamente.",

    // Programs: Poll
    POLL_NAME                       : "Encuesta final",
    POLL_TITLE                      : "Encuesta de fin de curso",



    // Streches
    STRECHES_NAME                   : "Tramos",
    STRECHES_SINGULAR               : "Tramo",
    STRECHES_DETAILS_TITLE          : "Detalles del tramo",
    STRECHES_CREATE_TITLE           : "Crear tramo",
    STRECHES_EDIT_TITLE             : "Editar tramo",
    STRECHES_VIEW_TITLE             : "Ver tramo",
    STRECHES_VIEW_URL_TITLE         : "Ver url del tramo",
    STRECHES_ACTIVATE_TITLE         : "Activar tramo",
    STRECHES_ACTIVATE_TEXT          : "¿Realmente desea activar el tramo <b>{0}</b>?",
    STRECHES_CANCEL_TITLE           : "Cancelar tramo",
    STRECHES_CANCEL_TEXT            : "¿Realmente desea cancelar el tramo <b>{0}</b>?",
    STRECHES_DELETE_TITLE           : "Eliminar tramo",
    STRECHES_DELETE_TEXT            : "¿Realmente desea eliminar el tramo <b>{0}</b>?",
    STRECHES_CREATING_TITLE         : "Creando tramo",
    STRECHES_FOR_TEXT               : "Para tramos",
    STRECHES_ACTIVE                 : "En curso",
    STRECHES_UPCOMING               : "Próximos",
    STRECHES_ENDED                  : "Finalizados",
    STRECHES_CREATING               : "Creando",
    STRECHES_PROGRAM                : "Programa del tramo",
    STRECHES_ITS_NAME               : "Nombre descriptivo del tramo",
    STRECHES_ITS_URL                : "Url del tramo",
    STRECHES_MODE                   : "Modalidad del tramo",
    STRECHES_MODE_VIRTUAL           : "Virtual por streaming",
    STRECHES_LOCATION               : "Lugar del tramo",
    STRECHES_ROOM                   : "Salón del tramo",
    STRECHES_FROM_DATE              : "Fecha de inicio",
    STRECHES_FROM_HOUR              : "Hora de inicio",
    STRECHES_TO_DATE                : "Fecha de cierre",
    STRECHES_TO_HOUR                : "Hora de cierre",
    STRECHES_INSC_FROM_DATE         : "Inicio de la inscripción",
    STRECHES_INSC_TO_DATE           : "Cierre de la inscripción",
    STRECHES_QUOTA                  : "Capacidad del tramo",
    STRECHES_CONVERSATIONS          : "Cantidad de conversaciones",
    STRECHES_MEETING_BY_GROUP       : "Reuniones por grupo",
    STRECHES_MEETING_AMOUNT         : "Cantidad de reuniones por grupo",
    STRECHES_MEETING_PRICE          : "Precio por reunión de grupo",
    STRECHES_FEATURED_IN_SITE       : "Mostrar en el sitio",
    STRECHES_FREE                   : "Tramo social (sin cargo)",
    STRECHES_COACH                  : "Tu coach asignado es",
    STRECHES_COACHES_SINGULAR       : "Facilitador",
    STRECHES_COACHES_PLURAL         : "Facilitadores",
    STRECHES_CONVERSATIONS_SINGULAR : "Tienes <b>{0}</b> conversación disponible.",
    STRECHES_CONVERSATIONS_PLURAL   : "Tienes <b>{0}</b> conversaciones disponibles.",
    STRECHES_STEP_COACHES           : "Asignar coaches",
    STRECHES_STEP_COACHES_HELP      : "Asignar al menos un coach de tipo facilitador y estado activo.",
    STRECHES_STEP_PRICES            : "Crear precios",
    STRECHES_STEP_PRICES_HELP       : "Crear al menos un precio para los alumnos con estado activo que no sea pronto pago.",
    STRECHES_STEP_SCHEDULES         : "Crear horarios",
    STRECHES_STEP_SCHEDULES_HELP    : "Crear al menos un horario.",
    STRECHES_STEP_CHATS             : "Crear charlas informativas",
    STRECHES_STEP_CHATS_HELP        : "Crear al menos una charla informativa con estado activa.",
    STRECHES_STEP_CLASSES           : "Crear clases",
    STRECHES_STEP_CLASSES_SINGULAR  : "Crear {0} clase con estado activa.",
    STRECHES_STEP_CLASSES_PLURAL    : "Crear {0} clases con estado activa.",
    STRECHES_STEP_MATERIALS         : "Asignar materiales",
    STRECHES_STEP_MATERIALS_HELP    : "Asignar al menos un material con estado activo.",
    STRECHES_STEP_GUIDES            : "Crear guías",
    STRECHES_STEP_GUIDES_HELP       : "Crear al menos una guía con estado activo.",
    STRECHES_STEP_SPECIALITIES      : "Asignar especialides de coaches",
    STRECHES_STEP_SPECIALITIES_HELP : "Asignar al menos una especialidad con estado activa y la máxima cantidad de conversaciones sea mayor a {0}.",
    STRECHES_STEP_QUESTIONS         : "Crear preguntas de encuesta final",
    STRECHES_STEP_QUESTIONS_HELP    : "Crear al menos una pregunta para la encuesta final.",
    STRECHES_STEP_STRECH            : "Completar datos del tramo",
    STRECHES_STEP_STRECH_HELP       : "Completar de forma válida todos los datos del tramo.",
    STRECHES_STEP_ACTIVATE          : "Activar tramo",
    STRECHES_NONE_AVAILABLE         : "No hay tramos disponibles.",
    STRECHES_ERROR_EXISTS           : "No existe el tramo indicado.",
    STRECHES_ERROR_ONGOING          : "No es posible realizar la acción en un tramo finalizado.",
    STRECHES_ERROR_ACTION           : "No es posible realizar la acción indicada.",
    STRECHES_ERROR_CREDENTIAL       : "No es posible ver el alumno indicado.",
    STRECHES_ERROR_NAME             : "Debe indicar el nombre del tramo.",
    STRECHES_ERROR_MODE             : "Debe indicar la modalidad del tramo.",
    STRECHES_ERROR_PROGRAM          : "Debe indicar el programa del tramo.",
    STRECHES_ERROR_LOCATION         : "Debe indicar el lugar del tramo.",
    STRECHES_ERROR_ROOM             : "Debe indicar el salón del tramo.",
    STRECHES_ERROR_FROM_DATE_EMPTY  : "Debe indicar la fecha de inicio.",
    STRECHES_ERROR_FROM_DATE_INV    : "La fecha de inicio es incorrecta.",
    STRECHES_ERROR_FROM_HOUR_EMPTY  : "Debe indicar la hora de inicio.",
    STRECHES_ERROR_FROM_HOUR_INV    : "La hora de inicio es incorrecta.",
    STRECHES_ERROR_TO_DATE_EMPTY    : "Debe indicar la fecha de cierre.",
    STRECHES_ERROR_TO_DATE_INV      : "La fecha de cierre es incorrecta.",
    STRECHES_ERROR_TO_DATE_PERIOD   : "La fecha de cierre debe ser posterior a la fecha de inicio.",
    STRECHES_ERROR_TO_HOUR_EMPTY    : "Debe indicar la hora de cierre.",
    STRECHES_ERROR_TO_HOUR_INV      : "La hora de cierre es incorrecta.",
    STRECHES_ERROR_TO_HOUR_PERIOD   : "La fecha y hora de cierre debe ser posterior a la fecha y hora de inicio.",
    STRECHES_ERROR_INSC_FROM_EMPTY  : "Debe indicar las fechas de inscripción si el tramos está activo.",
    STRECHES_ERROR_INSC_FROM_INV    : "La fecha de inicio de inscripción es incorrecta.",
    STRECHES_ERROR_INSC_TO_INVALID  : "La fecha de cierre de inscripción es incorrecta.",
    STRECHES_ERROR_INSC_TO_EMPTY    : "Debe indicar la fecha de cierre de inscripción.",
    STRECHES_ERROR_INSC_TO_PERIOD   : "La fecha de cierre debe ser posterior a la fecha de inicio de inscripción.",
    STRECHES_ERROR_INSC_TO_END      : "La fecha de cierre de inscripción debe ser anterior a la fecha de cierre del tramo.",
    STRECHES_ERROR_QUOTA            : "La capacidad debe ser un número mayor a cero.",
    STRECHES_ERROR_CONVERSATIONS    : "La cantidad de conversaciones debe ser un número mayor a cero.",
    STRECHES_ERROR_MEETING_AMOUNT   : "La cantidad de reuniones por grupo debe ser un número mayor a cero.",
    STRECHES_ERROR_MEETING_PRICE    : "El precio por reunión de grupo debe ser un número mayor a cero.",
    STRECHES_ERROR_ACTIVATE         : "No puede activar el tramo indicado.",
    STRECHES_ERROR_CANCEL           : "Solo puede cancelar tramos activos.",
    STRECHES_ERROR_DELETE           : "Solo puede eliminar tramos en estado borrador.",
    STRECHES_SUCCESS_CREATE         : "El tramo se creó correctamente.",
    STRECHES_SUCCESS_EDIT           : "El tramo se editó correctamente.",
    STRECHES_SUCCESS_ACTIVATE       : "El tramo se activó correctamente.",
    STRECHES_SUCCESS_CANCEL         : "El tramo se canceló correctamente.",
    STRECHES_SUCCESS_DELETE         : "El tramo se eliminó correctamente.",

    // Streches: Schedules
    SCHEDULES_NAME                  : "Horarios",
    SCHEDULES_SINGULAR              : "Horario",
    SCHEDULES_CREATE_TITLE          : "Crear horario",
    SCHEDULES_EDIT_TITLE            : "Editar horario",
    SCHEDULES_DELETE_TITLE          : "Eliminar horario",
    SCHEDULES_DELETE_TEXT           : "¿Realmente desea eliminar el horario <b>{0}</b>?",
    SCHEDULES_NONE_AVAILABLE        : "No hay horarios disponibles.",
    SCHEDULES_WEEKDAY               : "Día de la semana",
    SCHEDULES_FREQUENCY             : "Frecuencia (en semanas)",
    SCHEDULES_FREQUENCY_SHORT       : "Frecuencia",
    SCHEDULES_FREQUENCY_SINGULAR    : "Cada {0} semana",
    SCHEDULES_FREQUENCY_PLURAL      : "Cada {0} semanas",
    SCHEDULES_ERROR_EXISTS          : "No existe el horario indicado.",
    SCHEDULES_ERROR_WEEKDAY         : "Debe indicar el día de la semana.",
    SCHEDULES_ERROR_FROM_HOUR       : "Debe indicar la hora de inicio.",
    SCHEDULES_ERROR_TO_HOUR         : "Debe indicar la hora de cierre.",
    SCHEDULES_ERROR_FREQUENCY       : "Debe indicar la frecuencia como un número mayor a cero.",
    SCHEDULES_ERROR_STATUS          : "Debe indicar el estado del horario.",
    SCHEDULES_SUCCESS_CREATE        : "El horario se creó correctamente.",
    SCHEDULES_SUCCESS_EDIT          : "El horario se editó correctamente.",
    SCHEDULES_SUCCESS_DELETE        : "El horario se eliminó correctamente.",

    // Streches: Inscriptions
    INSCRIPTIONS_NAME               : "Inscripciones",
    INSCRIPTIONS_SINGULAR           : "Inscripción",
    INSCRIPTIONS_REQUESTS           : "Solicitudes de inscripción",
    INSCRIPTIONS_VIEW_TITLE         : "Ver inscripción",
    INSCRIPTIONS_CREATE_TITLE       : "Crear inscripción",
    INSCRIPTIONS_EDIT_TITLE         : "Editar inscripción",
    INSCRIPTIONS_CANCEL_TITLE       : "Cancelar inscripción",
    INSCRIPTIONS_CANCEL_TEXT        : "¿Realmente desea cancelar la inscripción de <b>{0}</b>?",
    INSCRIPTIONS_ACTIVATE_TITLE     : "Activar inscripción",
    INSCRIPTIONS_TAB_ACTIVE         : "Activas",
    INSCRIPTIONS_TAB_REQUESTED      : "Solicitudes",
    INSCRIPTIONS_TAB_CANCELLED      : "Canceladas",
    INSCRIPTIONS_TAB_INTERESTED     : "Interesadas",
    INSCRIPTIONS_NONE_AVAILABLE     : "No hay inscripciones disponibles.",
    INSCRIPTIONS_NONE_REQUESTS      : "No hay solicitudes de inscripción disponibles.",
    INSCRIPTIONS_PRICE              : "Precio del tramo",
    INSCRIPTIONS_PRICE_EXTRAS       : { "-2" : "Gratuito", "-1" : "Personalizado..." },
    INSCRIPTIONS_CONVERSATIONS      : "Cantidad de conversaciones",
    INSCRIPTIONS_CANCELLED          : "Cancelada el",
    INSCRIPTIONS_ERROR_EXISTS       : "No existe la inscripción indicada.",
    INSCRIPTIONS_ERROR_STUDENT      : "Debe indicar el alumno de la inscripción.",
    INSCRIPTIONS_ERROR_USED         : "Ya hay una inscripción para el alumno indicado.",
    INSCRIPTIONS_ERROR_STATUS       : "Debe indicar el estado de la inscripción.",
    INSCRIPTIONS_ERROR_PRICE        : "Debe indicar el precio de la inscripción.",
    INSCRIPTIONS_ERROR_PAYMENT      : "Debe indicar el método de pago de la inscripción.",
    INSCRIPTIONS_ERROR_CONV_LIMIT   : "Debe indicar la cantidad de conversacions como Todas, Ninguna o un valor entre 1 y el total de conversaciones del tramo.",
    INSCRIPTIONS_ERROR_CANCEL       : "No puede cancelar la inscripción indicada.",
    INSCRIPTIONS_SUCCESS_CREATE     : "La inscripción se creó correctamente.",
    INSCRIPTIONS_SUCCESS_EDIT       : "La inscripción se editó correctamente.",
    INSCRIPTIONS_SUCCESS_ACTIVATE   : "La inscripción se activó correctamente.",
    INSCRIPTIONS_SUCCESS_CANCEL     : "La inscripción se canceló correctamente.",

    // Streches: Inscriptions Reasons
    INSC_REASONS_NAME               : "Motivos de cancelación",
    INSC_REASONS_SINGULAR           : "Motivo de cancelación",
    INSC_REASONS_CREATE_TITLE       : "Crear motivo de cancelación",
    INSC_REASONS_EDIT_TITLE         : "Editar motivo de cancelación",
    INSC_REASONS_DELETE_TITLE       : "Eliminar motivo de cancelación",
    INSC_REASONS_DELETE_TEXT        : "¿Realmente desea eliminar el motivo de cancelación <b>{0}</b>?",
    INSC_REASONS_ITS_NAME           : "Nombre del motivo de cancelación",
    INSC_REASONS_USES               : "Cantidad de usos",
    INSC_REASONS_NONE_AVAILABLE     : "No hay motivos de cancelación disponibles.",
    INSC_REASONS_ERROR_EXISTS       : "No existe el motivo de cancelación indicado.",
    INSC_REASONS_ERROR_NAME         : "Debe indicar el nombre del motivo de cancelación.",
    INSC_REASONS_SUCCESS_CREATE     : "El motivo de cancelación se creó correctamente.",
    INSC_REASONS_SUCCESS_EDIT       : "El motivo de cancelación se editó correctamente.",
    INSC_REASONS_SUCCESS_DELETE     : "El motivo de cancelación se eliminó correctamente.",

    // Streches: Assignments
    INSC_ASSIGNS_NAME               : "Asignaciones",
    INSC_ASSIGNS_EDIT_TITLE         : "Asignar Coaches",
    INSC_ASSIGNS_EDIT_HELP          : "Indique los coaches asignados a <b>{0}</b> por cada especialidad:",
    INSC_ASSIGNS_SELECT             : "Seleccionar coach",
    INSC_ASSIGNS_ASSIGNS_SINGULAR   : "{0} asignación",
    INSC_ASSIGNS_ASSIGNS_PLURAL     : "{0} asignaciones",
    INSC_ASSIGNS_CONVS_SINGULAR     : "{0} conv. reportada",
    INSC_ASSIGNS_CONVS_PLURAL       : "{0} conv. reportadas",
    INSC_ASSIGNS_ADDITIONALS        : "Conv. adicionales",
    INSC_ASSIGNS_ERROR_AMOUNT       : "El alumno no tiene conversaciones disponibles.",
    INSC_ASSIGNS_ERROR_EDIT         : "Debe asignar un coach a cada especialidad.",
    INSC_ASSIGNS_ERROR_COACH        : "Debe indicar un coach.",
    INSC_ASSIGNS_ERROR_ADDITIONALS  : "El adicional de conversaciones debe ser un número mayor o igual a cero.",
    INSC_ASSIGNS_SUCCESS_EDIT       : "Las asignaciones de editaron correctamente",

    // Streches: Presentism
    PRESENTISM_NAME                 : "Presentismo",
    PRESENTISM_EDIT_TITLE           : "Editar presentismo",
    PRESENTISM_ASSISTED             : "Asistió",
    PRESENTISM_DIDNT_ASSISTED       : "No asistió",
    PRESENTISM_SUCCESS_ADD          : "Se marco al alumno como asistió",
    PRESENTISM_SUCCESS_REMOVE       : "Se marco al alumno como no asistió",

    // Streches: Groups
    GROUPS_NAME                     : "Grupos",
    GROUPS_SINGULAR                 : "Grupo",
    GROUPS_MINE                     : "Mi grupo",
    GROUPS_DETAILS_TITLE            : "Detalles del grupo",
    GROUPS_VIEW_TITLE               : "Ver grupo",
    GROUPS_CREATE_TITLE             : "Crear grupo",
    GROUPS_EDIT_TITLE               : "Editar grupo",
    GROUPS_DELETE_TITLE             : "Eliminar grupo",
    GROUPS_DELETE_TEXT              : "¿Realmente desea eliminar el grupo <b>{0}</b>?",
    GROUPS_ASSIGN_TITLE             : "Asignar a grupos",
    GROUPS_CHANGE_TITLE             : "Cambiar de grupo",
    GROUPS_CHANGE_REQUEST           : "Solicitar cambio de grupo",
    GROUPS_CHANGE_HELP              : "Indique el nuevo grupo de <b>{0}</b> para el tramo <b>{1}</b>:",
    GROUPS_NONE_AVAILABLE           : "No hay grupos disponibles.",
    GROUPS_ITS_NAME                 : "Nombre del grupo",
    GROUPS_SEARCH_MEMBER            : "Buscar alumno",
    GROUPS_SELECT_STUDENTS          : "Selecionar alumnos",
    GROUPS_MEMBERS                  : "Integrantes del grupo",
    GROUPS_ERROR_EXISTS             : "No existe el grupo indicado.",
    GROUPS_ERROR_STRECH             : "Debe indicar el tramo del grupo.",
    GROUPS_ERROR_NAME               : "Debe indicar el nombre del grupo.",
    GROUPS_ERROR_COACH              : "Debe indicar el coach del grupo.",
    GROUPS_ERROR_STATUS             : "Debe indicar el estado del grupo.",
    GROUPS_ERROR_MEMBERS_EMPTY      : "Debe indicar al menos 1 integrante en el grupo.",
    GROUPS_ERROR_MEMBERS_EXISTS     : "El alumno no pertenece al tramo indicado.",
    GROUPS_ERROR_MEMBERS_ASSIGNED   : "El alumno pertenece a otro grupo del tramo.",
    GROUPS_ERROR_MEMBERS_SAME       : "El alumno ya pertenece al grupo indicado.",
    GROUPS_SUCCESS_CREATE           : "El grupo se creó correctamente.",
    GROUPS_SUCCESS_EDIT             : "El grupo se editó correctamente.",
    GROUPS_SUCCESS_ASSIGN           : "Los alumnos se asignaron a los grupos correctamente.",
    GROUPS_SUCCESS_CHANGE           : "El alumno se cambió de grupo correctamente.",
    GROUPS_SUCCESS_DELETE           : "El grupo se eliminó correctamente.",

    // Streches: Meetings
    MEETINGS_NAME                   : "Reuniones de grupo",
    MEETINGS_SINGULAR               : "Reunión de grupo",
    MEETINGS_SHORT                  : "Reuniones",
    MEETINGS_MINE                   : "Mis reuniones",
    MEETINGS_VIEW_TITLE             : "Ver reunión",
    MEETINGS_CREATE_TITLE           : "Crear reunión",
    MEETINGS_PROGRAM_TITLE          : "Programar reunión",
    MEETINGS_EDIT_TITLE             : "Editar reunión",
    MEETINGS_DELETE_TITLE           : "Eliminar reunión",
    MEETINGS_DELETE_TEXT            : "¿Realmente desea eliminar la {0}?",
    MEETINGS_REPORT_TITLE           : "Reportar reunión",
    MEETINGS_CANCEL_TITLE           : "Cancelar reunión",
    MEETINGS_CANCEL_TEXT            : "¿Realmente desea cancelar la {0}?",
    MEETINGS_DESCRIPTION            : "Reunión del grupo <b>{0}</b> creada por <b>{1}</b> del día <b>{2}</b>",
    MEETINGS_CANCELLED_TEXT         : "Cancelada el <b>{0}</b> por el motivo <b>{1}</b>",
    MEETINGS_REPORT_TEXT            : "Reportada el día <b>{0}</b>",
    MEETINGS_PROGRAM                : "Programadas",
    MEETINGS_COMPLETED              : "Completadas",
    MEETINGS_REPORTED               : "Reportadas",
    MEETINGS_NREPORTED              : "No Reportadas",
    MEETINGS_CANCELLED              : "Canceladas",
    MEETINGS_GROUP                  : "Grupo de la reunión",
    MEETINGS_COURSE                 : "Curso de la reunión",
    MEETINGS_STRECH                 : "Tramo de la reunión",
    MEETINGS_MODE                   : "Modalidad de la reunión",
    MEETINGS_LOCATION               : "Lugar de la reunión",
    MEETINGS_LOCATION_CUSTOM        : "Dirección del lugar",
    MEETINGS_ROOM                   : "Salón de la reunión",
    MEETINGS_DATE                   : "Fecha de la reunión",
    MEETINGS_HOUR                   : "Hora de la reunión",
    MEETINGS_OBSERVATIONS           : "No son visibles para el alumno.",
    MEETINGS_PRESENTISM             : "Presentismo de la reunión",
    MEETINGS_REASON                 : "Deje el motivo de la cancelación",
    MEETINGS_NONE_AVAILABLE         : "No hay reuniones disponibles.",
    MEETINGS_ERROR_EXISTS           : "No existe la reunión indicada.",
    MEETINGS_ERROR_CREATE           : "No tiene permiso para crear una reunión.",
    MEETINGS_ERROR_EDIT             : "No tiene permiso para editar la reunión indicada.",
    MEETINGS_ERROR_AMOUNT           : "Ya creo la máxima cantidad de reuniones para el tramo.",
    MEETINGS_ERROR_REPORT           : "Sólo puede reportar reuniones completadas.",
    MEETINGS_ERROR_CANCEL           : "No es posible cancelar la reunión indicada.",
    MEETINGS_ERROR_DELETE           : "No es posible eliminar la reunión indicada.",
    MEETINGS_ERROR_MODE             : "Debe indicar el modo de la reunión.",
    MEETINGS_ERROR_LOCATION         : "Debe indicar el lugar de la reunión.",
    MEETINGS_ERROR_CUSTOM           : "Debe indicar el lugar personalizado.",
    MEETINGS_ERROR_ROOM             : "Debe indicar el salón de la reunión.",
    MEETINGS_ERROR_DATE             : "Debe indicar la fecha de la reunión.",
    MEETINGS_ERROR_HOUR             : "Debe indicar la hora de inicio de la reunión.",
    MEETINGS_ERROR_STRECH_FROM      : "La fecha y hora no debe ser previas al iniciio del tramo.",
    MEETINGS_ERROR_STRECH_TO        : "La fecha y hora no debe ser posterior al fin del tramo.",
    MEETINGS_ERROR_PRESENTISM       : "Debe indicar el presentismo de la reunión.",
    MEETINGS_ERROR_MEMBER           : "Uno de los alumnos indicados no pertenece al grupo.",
    MEETINGS_ERROR_ANSWERS          : "Debe responder a todas las preguntas del reporte.",
    MEETINGS_ERROR_REASON           : "Debe indicar el motivo de la cancelación.",
    MEETINGS_SUCCESS_CREATE         : "La reunión se creó correctamente.",
    MEETINGS_SUCCESS_EDIT           : "La reunión se editó correctamente.",
    MEETINGS_SUCCESS_REPORT         : "La reunión se reportó correctamente.",
    MEETINGS_SUCCESS_CANCEL         : "La reunión se canceló correctamente.",
    MEETINGS_SUCCESS_DELETE         : "La reunión se eliminó correctamente.",

    // Streches: Answers
    ANSWERS_TITLE                   : "Encuesta de fin de {0}",
    ANSWERS_TITLE_SHORT             : "Encuesta",
    ANSWERS_NAME                    : "Respuestas",
    ANSWERS_SINGULAR                : "Respuesta",
    ANSWERS_VIEW_TITLE              : "Ver respuestas",
    ANSWERS_EXPORT_TITLE            : "Exportar respuestas",
    ANSWERS_SECTION                 : "Sección: {0}",
    ANSWERS_MINE                    : "Mi respuesta",
    ANSWERS_NONE_AVAILABLE          : "No hay respuestas de fin de curso disponibles.",
    ANSWERS_DATE                    : "Respondida el",
    ANSWERS_REPLY                   : "Responder",
    ANSWERS_ERROR_CANT_ANSWER       : "No puede responder a las preguntas.",
    ANSWERS_ERROR_SEND_DATA         : "Debe enviar las respuestas.",
    ANSWERS_ERROR_SENT              : "Ya respondió la encuesta.",
    ANSWERS_ERROR_QUESTIONS         : "No hay preguntas para responer.",
    ANSWERS_ERROR_REQUIRED          : "Debe contestar la pregunta.",
    ANSWERS_ERROR_CUSTOM            : "Debe completar el texto de otro.",
    ANSWERS_ERROR_OPTION            : "La opción indicada es incrrecta.",
    ANSWERS_SUCCESS_SAVE            : "Gracias por responder la encuesta.",

    // Streches: Certificate
    CERTIFICATE_NAME                : "Certificado",
    CERTIFICATE_TITLE               : "Certificado de fin de curso",
    CERTIFICATE_TEXT                : "¡Muchas gracias por participar de <b>{0}</b>!<br />Te dejamos tu certificado.",



    // Conversations
    CONVERSATIONS_NAME              : "Conversaciones",
    CONVERSATIONS_SINGULAR          : "Conversación",
    CONVERSATIONS_MINE              : "Mis conversaciones",
    CONVERSATIONS_VIEW_TITLE        : "Ver conversación",
    CONVERSATIONS_CREATE_TITLE      : "Crear conversación",
    CONVERSATIONS_EDIT_TITLE        : "Editar conversación",
    CONVERSATIONS_DELETE_TITLE      : "Eliminar conversación",
    CONVERSATIONS_DELETE_TEXT       : "¿Realmente desea eliminar la {0}?",
    CONVERSATIONS_ASSIGN_TITLE      : "Asignar conversación",
    CONVERSATIONS_REQUEST_TITLE     : "Solicitar conversación",
    CONVERSATIONS_REQUEST_CHANGE    : "Solicitar cambio de coach",
    CONVERSATIONS_UNREQUEST_TITLE   : "Cancelar solicitud",
    CONVERSATIONS_UNREQUEST_TEXT    : "¿Realmente desea cancelar la solicitud de la conversación?",
    CONVERSATIONS_PROGRAM_TITLE     : "Programar conversación",
    CONVERSATIONS_REPORT_TITLE      : "Reportar conversación",
    CONVERSATIONS_CANCEL_TITLE      : "Cancelar conversación",
    CONVERSATIONS_CANCEL_TEXT       : "¿Realmente desea cancelar la {0}?",
    CONVERSATIONS_DESCRIPTION       : "Conversación entre <b>{0}</b> y <b>{1}</b> del día <b>{2}</b>",
    CONVERSATIONS_CANCELLED_WITH    : "Cancelada con aviso el <b>{0}</b> por el motivo <b>{1}</b>",
    CONVERSATIONS_CANCELLED_WITHOUT : "Cancelada sin aviso el <b>{0}</b> por el motivo <b>{1}</b>",
    CONVERSATIONS_REPORT_TEXT       : "Número de reportada <b>{0}</b> el día <b>{1}</b>",
    CONVERSATIONS_REQUEST           : "Solicitadas",
    CONVERSATIONS_ASSIGNED          : "Asignadas",
    CONVERSATIONS_PROGRAM           : "Programadas",
    CONVERSATIONS_COMPLETED         : "Completadas",
    CONVERSATIONS_REPORTED          : "Reportadas",
    CONVERSATIONS_NREPORTED         : "No Reportadas",
    CONVERSATIONS_CANCELLED         : "Canceladas",
    CONVERSATIONS_COACHEE           : "Coachee",
    CONVERSATIONS_VIEW_COACHEE      : "Ver coachee",
    CONVERSATIONS_COURSE            : "Curso de la conversación",
    CONVERSATIONS_STRECH            : "Tramo de la conversación",
    CONVERSATIONS_MODE              : "Modalidad de la conversación",
    CONVERSATIONS_LOCATION          : "Lugar de la conversación",
    CONVERSATIONS_LOCATION_CUSTOM   : "Dirección del lugar",
    CONVERSATIONS_ROOM              : "Salón de la conversación",
    CONVERSATIONS_DATE              : "Fecha de la conversación",
    CONVERSATIONS_HOUR              : "Hora de la conversación",
    CONVERSATIONS_PRICE             : "Precio de la conversación",
    CONVERSATIONS_OBSERVATIONS      : "No son visibles para el alumno.",
    CONVERSATIONS_CANT_CREATE       : "Solo puede crear conversaciones cuando al menos uno de los cursos en lo que esta asignado como coach esta activo y en progreso.",
    CONVERSATIONS_CANT_REQUEST      : "Al finalizar tu primer encuentro, y si el curso tiene habilitada conversaciones, se habilitara esta opción.",
    CONVERSATIONS_REQUEST_HELP      : "Puede solicitar una conversación con su coach asignado. Dejanos un mensaje y el curso, y nos contactaremos para confirmar su solicitud.",
    CONVERSATIONS_REQUEST_STUDENT   : "El alumno <b>{0}</b> solicitó una conversación dejando el siguiente mensaje.",
    CONVERSATIONS_REQUEST_COACH     : "El coach <b>{0}</b> solicitó una conversación dejando el siguiente mensaje.",
    CONVERSATIONS_INVOICE_PRICE     : "Precio pagado al coach",
    CONVERSATIONS_MESSAGE           : "Mensaje para la conversación",
    CONVERSATIONS_MESSAGE_REQUEST   : "Deje un mensaje para la solicitud",
    CONVERSATIONS_MESSAGE_ASSIGN    : "Deje un mensaje para la asignación",
    CONVERSATIONS_REASON            : "Deje el motivo de la cancelación",
    CONVERSATIONS_NOTICE            : "¿Se canceló con aviso?",
    CONVERSATIONS_NONE_AVAILABLE    : "No hay conversaciones disponibles.",
    CONVERSATIONS_ERROR_EXISTS      : "No existe la conversación indicada.",
    CONVERSATIONS_ERROR_ASSIGN      : "No tiene permiso para asignar conversaciones.",
    CONVERSATIONS_ERROR_EDIT        : "No tiene permiso para editar la conversación indicada.",
    CONVERSATIONS_ERROR_PROGRAM     : "Sólo puede programar conversaciones solicitadas.",
    CONVERSATIONS_ERROR_REPORT      : "Sólo puede reportar conversaciones completadas.",
    CONVERSATIONS_ERROR_CANCEL      : "No es posible cancelar la conversación indicada.",
    CONVERSATIONS_ERROR_DELETE      : "No es posible eliminar la conversación indicada.",
    CONVERSATIONS_ERROR_ACCESS_NONE : "Debe indicar el tipo de usuario.",
    CONVERSATIONS_ERROR_ACCESS_INV  : "El tipo de usuario indicado no existe.",
    CONVERSATIONS_ERROR_COACH       : "Debe indicar el coach asignado.",
    CONVERSATIONS_ERROR_MODE        : "Debe indicar el modo de la conversación.",
    CONVERSATIONS_ERROR_LOCATION    : "Debe indicar el lugar de la conversación.",
    CONVERSATIONS_ERROR_CUSTOM      : "Debe indicar el lugar personalizado.",
    CONVERSATIONS_ERROR_ROOM        : "Debe indicar el salón de la conversación.",
    CONVERSATIONS_ERROR_DATE        : "Debe indicar la fecha de la conversación.",
    CONVERSATIONS_ERROR_HOUR        : "Debe indicar la hora de inicio de la conversación.",
    CONVERSATIONS_ERROR_STRECH      : "Debe indicar el curso de la conversación.",
    CONVERSATIONS_ERROR_REQ_TIME    : "Solo puede solicitar una conversación hasta 48 horas antes de terminado el curso.",
    CONVERSATIONS_ERROR_STRECH_FROM : "La fecha y hora no debe ser previas al iniciio del tramo.",
    CONVERSATIONS_ERROR_STRECH_TO   : "La fecha y hora no debe ser posterior al fin del tramo.",
    CONVERSATIONS_ERROR_SPECIALITY  : "Debe indicar la especialidad de la conversación.",
    CONVERSATIONS_ERROR_PRICE       : "Debe indicar un precio válido para la conversación.",
    CONVERSATIONS_ERROR_MESSAGE     : "Debe indicar el mensaje para la conversación.",
    CONVERSATIONS_ERROR_REQUEST     : "Ya hay una solicitud para el curso indicado.",
    CONVERSATIONS_ERROR_STUD_COACH  : "No tiene asignado un coach para el curso indicado.",
    CONVERSATIONS_ERROR_AMOUNT_MINE : "Llegaste a la máxima cantidad de conversaciones. Si desea solicitar más puede pedirla desde sus consultas.",
    CONVERSATIONS_ERROR_AMOUNT      : "El alumno ya solicitó la máxima cantidad de conversaciones.",
    CONVERSATIONS_ERROR_ANSWERS     : "Debe responder a todas las preguntas del reporte.",
    CONVERSATIONS_ERROR_REASON      : "Debe indicar el motivo de la cancelación.",
    CONVERSATIONS_SUCCESS_REQUEST   : "La solicitud se creó correctamente.",
    CONVERSATIONS_SUCCESS_UNREQUEST : "La solicitud se canceló correctamente.",
    CONVERSATIONS_SUCCESS_ASSIGN    : "La conversación se asignó correctamente.",
    CONVERSATIONS_SUCCESS_CREATE    : "La conversación se creó correctamente.",
    CONVERSATIONS_SUCCESS_PROGRAM   : "La conversación se programó correctamente.",
    CONVERSATIONS_SUCCESS_EDIT      : "La conversación se editó correctamente.",
    CONVERSATIONS_SUCCESS_REPORT    : "La conversación se reportó correctamente.",
    CONVERSATIONS_SUCCESS_CANCEL    : "La conversación se canceló correctamente.",
    CONVERSATIONS_SUCCESS_DELETE    : "La conversación se eliminó correctamente.",



    // Campaigns
    CAMPAIGNS_NAME                  : "Campañas",
    CAMPAIGNS_SINGULAR              : "Campaña",
    CAMPAIGNS_DETAILS_TITLE         : "Detalles de la campaña",
    CAMPAIGNS_VIEW_TITLE            : "Ver campaña",
    CAMPAIGNS_SEND_TITLE            : "Enviar campaña",
    CAMPAIGNS_EDIT_TITLE            : "Editar campaña",
    CAMPAIGNS_RESEND_TITLE          : "Reenviar campaña",
    CAMPAIGNS_PAUSE_TITLE           : "Pausar campaña",
    CAMPAIGNS_PAUSE_TEXT            : "¿Realmente desea pausar la campaña <b>{0}</b>?",
    CAMPAIGNS_DELETE_TITLE          : "Eliminar campaña",
    CAMPAIGNS_DELETE_TEXT           : "¿Realmente desea eliminar la campaña <b>{0}</b>?",
    CAMPAIGNS_STATS_SENT            : "Envíos",
    CAMPAIGNS_STATS_OPENS           : "Aperturas",
    CAMPAIGNS_STATS_CLICKS          : "Clicks",
    CAMPAIGNS_SEND_WHEN             : "Cuándo se envía la campaña",
    CAMPAIGNS_SEND_WHEN_OPTIONS     : { 1 : "Definir después", 2 : "En 15 minutos", 3 : "En 1 hora", 4 : "En 1 día", 5 : "Seleccionar fecha" },
    CAMPAIGNS_SEND_DATE             : "Día de envío",
    CAMPAIGNS_SEND_HOUR             : "Hora de envío",
    CAMPAIGNS_SEND_TO_SINGULAR      : "Enviar a {0} usuario",
    CAMPAIGNS_SEND_TO_PLURAL        : "Enviar a {0} usuarios",
    CAMPAIGNS_SUBJECT               : "Asunto",
    CAMPAIGNS_ITS_SUBJECT           : "Asunto de la campaña",
    CAMPAIGNS_TEMPLATE              : "Template",
    CAMPAIGNS_ITS_TEMPLATE          : "Template de la campaña",
    CAMPAIGNS_MESSAGE               : "Mensaje de la campaña",
    CAMPAIGNS_IMAGE                 : "Imagen de la campaña",
    CAMPAIGNS_BUTTON                : "Botón para usar con un vínculo",
    CAMPAIGNS_LINK                  : "Url para usar en la campaña",
    CAMPAIGNS_LINK_HELP             : "La url se agrega como vínculo al botón o a la imagen si hay.",
    CAMPAIGNS_USERS                 : "A quien se envía la campaña",
    CAMPAIGNS_NOT_SUBSCRIBED        : " <i style='padding-left:4px'>({0})</i>",
    CAMPAIGNS_QUANTITY              : "Cantidad de usuarios",
    CAMPAIGNS_STATE                 : "Estado de la campaña",
    CAMPAIGNS_STATE_NAMES           : { 1 : "Error", 2 : "Borrador", 3 : "Programada", 4 : "Enviada", 5 : "Pausada" },
    CAMPAIGNS_STATE_COLORS          : { 1 : "red", 2 : "gray", 3 : "yellow", 4 : "green", 5 : "red" },
    CAMPAIGNS_NONE_AVAILABLE        : "No hay campañas disponibles",
    CAMPAIGNS_OPEN                  : "Visto",
    CAMPAIGNS_CLICK                 : "Click",
    CAMPAIGNS_CONTENT               : "Contenido",
    CAMPAIGNS_CONTENT_TITLE         : "Contenido de la campaña",
    CAMPAIGNS_CONTENT_NONE          : "No hay un contenido disponible",
    CAMPAIGNS_ERROR_EXISTS          : "No existe la campaña indicada.",
    CAMPAIGNS_ERROR_EDIT            : "No se puede editar la campaña indicada.",
    CAMPAIGNS_ERROR_SUBJECT         : "Debe indicar el asunto de la campaña.",
    CAMPAIGNS_ERROR_TEMP_EMPTY      : "Debe indicar el template de la campaña.",
    CAMPAIGNS_ERROR_TEMP_INVALID    : "La campaña indicada no existe.",
    CAMPAIGNS_ERROR_MESSAGE         : "Debe indicar el mensaje o una imagen para la campaña.",
    CAMPAIGNS_ERROR_LINK_BUTTON     : "Debe indicar una url para usar con el botón",
    CAMPAIGNS_ERROR_LINK_ONLY       : "Debe indicar un botón o imagen para para usar con la url",
    CAMPAIGNS_ERROR_SEND_WHEN_EMPTY : "Debe indicar cuando se envía la campaña.",
    CAMPAIGNS_ERROR_SEND_WHEN_INV   : "La opción indicada es incorrecta.",
    CAMPAIGNS_ERROR_SEND_DATE_EMPTY : "Debe indicar la fecha de envío.",
    CAMPAIGNS_ERROR_SEND_DATE_INV   : "La fecha de envío es incorrecta.",
    CAMPAIGNS_ERROR_SEND_DATE_NOW   : "La fecha y hora de envío deben ser posterior a la fecha y hora actual.",
    CAMPAIGNS_ERROR_SEND_HOUR_EMPTY : "Debe indicar la hora de envío.",
    CAMPAIGNS_ERROR_SEND_HOUR_INV   : "La hora de envío es incorrecta.",
    CAMPAIGNS_ERROR_NO_USERS        : "Debe indicar al menos un usuario.",
    CAMPAIGNS_ERROR_USER_EMAIL      : "El email del usuario es inválido.",
    CAMPAIGNS_ERROR_USER_CLEANED    : "El usuario esta marcado como rebotado en MailChimp.",
    CAMPAIGNS_ERROR_USER_SUBSCRIBED : "El usuario no esta subscripto en MailChimp.",
    CAMPAIGNS_ERROR_PAUSE           : "No se puede pausar la campaña indicada.",
    CAMPAIGNS_ERROR_DELETE          : "No se puede eliminar la campaña indicada.",
    CAMPAIGNS_SUCCESS_SEND          : "La campaña se creó correctamente.",
    CAMPAIGNS_SUCCESS_EDIT          : "La campaña se editó correctamente.",
    CAMPAIGNS_SUCCESS_PAUSE         : "La campaña se pausó correctamente.",
    CAMPAIGNS_SUCCESS_DELETE        : "La campaña se eliminó correctamente.",



    // Reports
    REPORTS_NAME                    : "Reportes",
    REPORTS_INSCRIPTIONS            : "Reporte de inscripciones",
    REPORTS_CONVERSATIONS           : "Reporte de conversaciones",
    REPORTS_MEETINGS                : "Reporte de reuniones de grupo",
    REPORTS_GUIDES                  : "Reporte de guías",
    REPORTS_POLLS                   : "Reporte de encuestas",
    REPORTS_INVOICES                : "Reporte de facturas",
    REPORTS_GRAPH                   : "Tipo de gráfico",
    REPORTS_DATA                    : "Tipo de dato",
    REPORTS_DATA_OPTIONS            : { 1 : "Cantidad", 2 : "Precio" },
    REPORTS_INSCRIPTION_STATUS      : "Estado de inscripción",
    REPORTS_STRECH_TYPE             : "Tipo de tramo",
    REPORTS_STRECH_TYPE_OPTIONS     : { 1 : "En progreso", 2 : "Completados", 3 : "Próximos" },
    REPORTS_CONVERSATION_STATUS     : "Estado de conversación",
    REPORTS_MEETING_STATUS          : "Estado de reunión",
    REPORTS_INVOICE_STATUS          : "Estado de factura",
    REPORTS_INVOICE_TYPE            : "Tipo de pago",
    REPORTS_INVOICE_TYPE_OPTIONS    : { 1 : "Sólo honorarios", 2 : "Sólo conversaciones", 3 : "Sólo reuniones" },
    REPORTS_FEEDBACK_OPTIONS        : { 1 : "Con feedback", 2 : "Sin feedback" },
    REPORTS_MODE                    : "Modalidad",
    REPORTS_DUES                    : "Cuotas",
    REPORTS_DUES_SINGULAR           : "1 cuota",
    REPORTS_DUES_PLURAL             : "{0} cuotas",
    REPORTS_TOTAL_AMOUNT            : "Cantidad",
    REPORTS_TOTAL_PRICE             : "Importe",
    REPORTS_NONE_AVAILABLE          : "No hay datos disponibles",
    REPORTS_DOWNLOAD_LIST           : "Descargar listado",
    REPORTS_GRAPH_TYPE_1            : "Por curso",
    REPORTS_GRAPH_TYPE_2            : "Por programa",
    REPORTS_GRAPH_TYPE_3            : "Por tramo",
    REPORTS_GRAPH_TYPE_4            : "Por tipo",
    REPORTS_GRAPH_TYPE_5            : "Por modalidad",
    REPORTS_GRAPH_TYPE_6            : "Por cuotas",
    REPORTS_GRAPH_TYPE_7            : "Por método de pago",
    REPORTS_GRAPH_TYPE_8            : "Por conversaciones",
    REPORTS_GRAPH_TYPE_9            : "Por facilitador",
    REPORTS_GRAPH_TYPE_10           : "Por coach",
    REPORTS_GRAPH_TYPE_11           : "Por coachee",
    REPORTS_GRAPH_TYPE_12           : "Por especialidad",
    REPORTS_GRAPH_TYPE_13           : "Por guía",



    // Leads
    LEADS_NAME                      : "Leads",
    LEADS_SINGULAR                  : "Lead",
    LEADS_FILTER_TITLE              : "Filtrar leads",
    LEADS_DETAILS_TITLE             : "Detalles del lead",
    LEADS_VIEW_TITLE                : "Ver lead",
    LEADS_CREATE_TITLE              : "Crear lead",
    LEADS_EDIT_TITLE                : "Editar lead",
    LEADS_ASSIGN_TITLE              : "Asignar lead",
    LEADS_CLOSE_TITLE               : "Cerrar lead",
    LEADS_CLOSE_REASON              : "Motivo del cierre",
    LEADS_CLOSE_INSCRIPTION         : "Inscripción generada por el usuario",
    LEADS_OPEN_TITLE                : "Abrir lead",
    LEADS_OPEN_TEXT                 : "¿Realmente desea abrir el lead <b>{0}</b>?",
    LEADS_DELETE_TITLE              : "Eliminar lead",
    LEADS_DELETE_TEXT               : "¿Realmente desea eliminar el lead <b>{0}</b>?",
    LEADS_RECEIVED_TAB              : "Leads recibidos",
    LEADS_RESPONDED_TAB             : "Leads respondidos",
    LEADS_CLOSED_TAB                : "Leads cerrados",
    LEADS_ORIGIN                    : "Origen del lead",
    LEADS_INTEREST                  : "Interés del lead",
    LEADS_CONTACT                   : "Contacto",
    LEADS_CONTACT_NONE              : "No hay contactos disponibles",
    LEADS_DESCRIPTION               : "Consulta",
    LEADS_ITS_DESCRIPTION           : "Consulta del lead",
    LEADS_ASSIGNED                  : "Asignado",
    LEADS_ASSIGNED_USER             : "Usuario asignado",
    LEADS_ASSIGNED_EXTRAS           : { "-1" : "Asignarmela" },
    LEADS_ASSIGNED_REASON           : "Motivo de asignación",
    LEADS_CLOSED_REASON             : "Cerrado por",
    LEADS_SYSTEM                    : "Protagonista",
    LEADS_TITLE                     : "Consulta de {1}",
    LEADS_HISTORY_TITLE             : "<b>{0}</b> consultó {1}",
    LEADS_HISTORY_REPLY             : "<b>{0}</b> respondió {1}",
    LEADS_HISTORY_REASON            : "<b>Motivo:</b> {0}",
    LEADS_HISTORY_CLOSE             : "{0}<br><b>Tramo:</b> {1}",
    LEADS_HISTORY_1                 : "<b>{0}</b> creó el lead desde <b>{1}</b> con interés <b>{2}</b>.",
    LEADS_HISTORY_2                 : "<b>{0}</b> editó el lead con el origen <b>{1}</b> y el interés <b>{2}</b>.",
    LEADS_HISTORY_31                : "<b>{0}</b> asignó el lead a <b>{1}</b>.",
    LEADS_HISTORY_32                : "<b>{0}</b> se asignó el lead.",
    LEADS_HISTORY_4                 : "<b>{0}</b> cerró el lead.",
    LEADS_HISTORY_5                 : "<b>{0}</b> abrió el lead.",
    LEADS_NONE_AVAILABLE            : "No hay leads disponibles.",
    LEADS_ERROR_EXISTS              : "No existe el lead indicado.",
    LEADS_ERROR_USER                : "Debe indicar el alumno o coach que hizo la consulta.",
    LEADS_ERROR_ORIGIN              : "Debe indicar el origen del lead.",
    LEADS_ERROR_INTEREST            : "Debe indicar el interés del lead.",
    LEADS_ERROR_DESCRIPTION         : "Debe indicar la descripción del lead.",
    LEADS_ERROR_MESSAGE             : "Debe indicar el mensaje a enviar.",
    LEADS_ERROR_ASSIGNED_EMTPY      : "Debe indicar a quien asignar el lead.",
    LEADS_ERROR_ASSIGNED_INVALID    : "No puede asignar el lead al usuario indicado.",
    LEADS_ERROR_EMAIL               : "No se puede enviar el email de inscripción.",
    LEADS_ERROR_CLOSE               : "No puede cerrar el lead indicado.",
    LEADS_ERROR_CLOSE_REASON        : "Debe indicar el motivo del cierre del lead.",
    LEADS_ERROR_CLOSE_INSCRIPTION   : "Debe indicar la inscripción asignada al lead.",
    LEADS_ERROR_OPEN                : "No puede abrir el lead indicado.",
    LEADS_SUCCESS_CREATE            : "El lead se creó correctamente.",
    LEADS_SUCCESS_EDIT              : "El lead se editó correctamente.",
    LEADS_SUCCESS_MESSAGE           : "El mensaje se envió correctamente.",
    LEADS_SUCCESS_ASSIGN            : "El lead se asignó correctamente.",
    LEADS_SUCCESS_CLOSE             : "El lead se cerró correctamente.",
    LEADS_SUCCESS_OPEN              : "El lead se abrió correctamente.",
    LEADS_SUCCESS_DELETE            : "El lead se eliminó correctamente.",

    // Leads: Origins
    ORIGINS_NAME                    : "Orígenes",
    ORIGINS_SINGULAR                : "Origen",
    ORIGINS_CREATE_TITLE            : "Crear origen",
    ORIGINS_EDIT_TITLE              : "Editar origen",
    ORIGINS_DELETE_TITLE            : "Eliminar origen",
    ORIGINS_DELETE_TEXT             : "¿Realmente desea eliminar el origen <b>{0}</b>?",
    ORIGINS_ITS_NAME                : "Nombre del origen",
    ORIGINS_NONE_AVAILABLE          : "No hay orígenes disponibles.",
    ORIGINS_ERROR_EXISTS            : "No existe el origen indicado.",
    ORIGINS_ERROR_NAME_EMPTY        : "Debe indicar el nombre del origen.",
    ORIGINS_ERROR_NAME_EXISTS       : "Ya existe otro origen con el mismo nombre.",
    ORIGINS_ERROR_STATUS            : "Debe indicar el estado del origen.",
    ORIGINS_SUCCESS_CREATE          : "El origen se creó correctamente.",
    ORIGINS_SUCCESS_EDIT            : "El origen se editó correctamente.",
    ORIGINS_SUCCESS_DELETE          : "El origen se eliminó correctamente.",

    // Leads: Assignments
    ASSIGNMENTS_NAME                : "Asignaciones iniciales",
    ASSIGNMENTS_SINGULAR            : "Asignación inicial",
    ASSIGNMENTS_CREATE_TITLE        : "Crear asignación",
    ASSIGNMENTS_EDIT_TITLE          : "Editar asignación",
    ASSIGNMENTS_DELETE_TITLE        : "Eliminar asignación",
    ASSIGNMENTS_DELETE_TEXT         : "¿Realmente desea eliminar la asignación inicial <b>{0}</b>?",
    ASSIGNMENTS_ASSIGNED_TO         : "Asignados a",
    ASSIGNMENTS_INTEREST            : "Interés asociado a la asignación",
    ASSIGNMENTS_CREDENTIAL          : "Usuario a quien se le asignan los leads.",
    ASSIGNMENTS_NONE_AVAILABLE      : "No hay asignaciones iniciales disponibles.",
    ASSIGNMENTS_ERROR_EXISTS        : "No existe la asignación inicial indicada.",
    ASSIGNMENTS_ERROR_INTEREST      : "Debe indicar el interés asociado a la asignación.",
    ASSIGNMENTS_ERROR_CRED_EMPTY    : "Debe indicar el usario a quien asignar.",
    ASSIGNMENTS_ERROR_CRED_INVALID  : "El usuario indicado no existe o no esta habilitado para gestionar leads.",
    ASSIGNMENTS_ERROR_REPEATED      : "Ya existe una asignación para el interés y usuario indicados.",
    ASSIGNMENTS_ERROR_STATUS        : "Debe indicar el estado de la asignación.",
    ASSIGNMENTS_SUCCESS_CREATE      : "La asignación inicial se creó correctamente.",
    ASSIGNMENTS_SUCCESS_EDIT        : "La asignación inicial se editó correctamente.",
    ASSIGNMENTS_SUCCESS_DELETE      : "La asignación inicial se eliminó correctamente.",

    // Leads: Close Reasons
    CLOSE_REASONS_NAME              : "Motivos de cerrado",
    CLOSE_REASONS_SINGULAR          : "Motivo de cerrado",
    CLOSE_REASONS_CREATE_TITLE      : "Crear motivo de cerrado",
    CLOSE_REASONS_EDIT_TITLE        : "Editar motivo de cerrado",
    CLOSE_REASONS_DELETE_TITLE      : "Eliminar motivo de cerrado",
    CLOSE_REASONS_DELETE_TEXT       : "¿Realmente desea eliminar el motivo de cerrado <b>{0}</b>?",
    CLOSE_REASONS_ITS_NAME          : "Nombre del motivo de cerrado",
    CLOSE_REASONS_USES              : "Cantidad de usos",
    CLOSE_REASONS_NONE_AVAILABLE    : "No hay motivos de cerrado disponibles.",
    CLOSE_REASONS_ERROR_EXISTS      : "No existe el motivo de cerrado indicado.",
    CLOSE_REASONS_ERROR_NAME        : "Debe indicar el nombre del motivo de cerrado.",
    CLOSE_REASONS_ERROR_DELETE      : "No se puede eliminar el motivo de cerrado indicado.",
    CLOSE_REASONS_SUCCESS_CREATE    : "El motivo de cerrado se creó correctamente.",
    CLOSE_REASONS_SUCCESS_EDIT      : "El motivo de cerrado se editó correctamente.",
    CLOSE_REASONS_SUCCESS_DELETE    : "El motivo de cerrado se eliminó correctamente.",



    // Tickets: Help
    HELP_NAME                       : "Ayuda",
    HELP_NONE_QUESTIONS             : "No hay preguntas disponibles",
    HELP_NOT_AVAILABLE              : "¿No encontras lo que buscas? Envianos una consulta desde el siguiente link.",

    // Tickets: Queries
    TICKETS_NAME                    : "Consultas",
    TICKETS_SINGULAR                : "Consulta",
    TICKETS_VIEW_TITLE              : "Ver consulta",
    TICKETS_CREATE_TITLE            : "Crear consulta",
    TICKETS_EDIT_TITLE              : "Editar consulta",
    TICKETS_DELETE_TITLE            : "Eliminar consulta",
    TICKETS_DELETE_TEXT             : "¿Realmente desea eliminar la consulta <b>{0}</b>?",
    TICKETS_OPEN_TITLE              : "Abrir consulta",
    TICKETS_OPEN_TEXT               : "¿Realmente desea abrir la consulta <b>{0}</b>?",
    TICKETS_OPEN_TAB                : "Consultas abiertas",
    TICKETS_OPEN                    : "Abrir",
    TICKETS_CLOSE_TITLE             : "Cerrar consulta",
    TICKETS_CLOSE_TEXT              : "¿Realmente desea cerrar la consulta <b>{0}</b>?",
    TICKETS_CLOSE_TAB               : "Consultas cerradas",
    TICKETS_ASSIGN_TITLE            : "Asignar consulta",
    TICKETS_ASSIGN                  : "Asignar",
    TICKETS_MUTE_TITLE              : "Silenciar consulta",
    TICKETS_UNMUTE_TITLE            : "Notificar consulta",
    TICKETS_NONE_AVAILABLE          : "No hay consultas disponibles.",
    TICKETS_MESSAGE                 : "Mensaje de la consulta",
    TICKETS_ASSIGNED                : "Asignada a",
    TICKETS_NONE_MESSAGES           : "No hay mensajes disponibles.",
    TICKETS_WRITE_MESSAGE           : "Escribir mensaje",
    TICKETS_EDIT_MESSAGE            : "Editar mensaje",
    TICKETS_DELETE_MESSAGE          : "Eliminar mensaje",
    TICKETS_DELETE_MESSAGE_TEXT     : "¿Realmente desea eliminar el mensaje <b>{0}</b>?",
    TICKETS_ERROR_EXISTS            : "No existe la consulta indicada.",
    TICKETS_ERROR_TYPE              : "Por favor, indique el tipo de la consulta.",
    TICKETS_ERROR_ADMIN             : "No existe el usuario a quien desea asignar la consulta.",
    TICKETS_ERROR_EDIT              : "No tiene permiso para editar la consulta.",
    TICKETS_ERROR_DELETE            : "No tiene permiso para eliminar la consulta.",
    TICKETS_ERROR_ASSIGN            : "No tiene permiso para asignar la consulta.",
    TICKETS_ERROR_OPEN              : "No tiene permiso para abrir la consulta.",
    TICKETS_ERROR_CLOSE             : "No tiene permiso para cerrar la consulta.",
    TICKETS_ERROR_STRECH            : "Por favor, indique un curso.",
    TICKETS_ERROR_MESSAGE           : "Por favor, indique un mensaje.",
    TICKETS_ERROR_MESSAGE_EXISTS    : "No existe el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_EDIT      : "No tiene permiso para editar el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_DELETE    : "No tiene permiso para eliminar el mensaje indicado.",
    TICKETS_SUCCESS_CREATE          : "La consulta se creó correctamente.",
    TICKETS_SUCCESS_EDIT            : "La consulta se editó correctamente.",
    TICKETS_SUCCESS_DELETE          : "La consulta se eliminó correctamente.",
    TICKETS_SUCCESS_ASSIGN          : "La consulta se asignó correctamente.",
    TICKETS_SUCCESS_OPEN            : "La consulta se abrió correctamente.",
    TICKETS_SUCCESS_CLOSE           : "La consulta se cerró correctamente.",
    TICKETS_SUCCESS_MUTE            : "La consulta se silenció correctamente.",
    TICKETS_SUCCESS_UNMUTE          : "La consulta se desilenció correctamente.",
    TICKETS_SUCCESS_ADD_MESSAGE     : "El mensaje se agregó correctamente.",
    TICKETS_SUCCESS_EDIT_MESSAGE    : "El mensaje se editó correctamente.",
    TICKETS_SUCCESS_DELETE_MESSAGE  : "El mensaje se eliminó correctamente.",

    // Tickets: Query Types
    TICKETS_TYPES_NAME              : "Tipos de consultas",
    TICKETS_TYPES_SINGULAR          : "Tipo de consulta",
    TICKETS_TYPES_CREATE_TITLE      : "Crear tipo de consulta",
    TICKETS_TYPES_EDIT_TITLE        : "Editar tipo de consulta",
    TICKETS_TYPES_DELETE_TITLE      : "Eliminar tipo de consulta",
    TICKETS_TYPES_DELETE_TEXT       : "¿Realmente desea eliminar el tipo de consulta <b>{0}</b>?",
    TICKETS_TYPES_ITS_NAME          : "Nombre del tipo de consulta",
    TICKETS_TYPES_CREDENTIAL        : "Asignar automáticamente a",
    TICKETS_TYPES_ASSIGNED          : "Asignado a",
    TICKETS_TYPES_USE               : "Uso especial",
    TICKETS_TYPES_USES              : { 1 : "Cambio de coach", 2 : "Cambio de grupo", 3 : "Cambio de honorarios" },
    TICKETS_TYPES_MESSAGE           : "Mensaje a mostrar al usar este tipo de consulta",
    TICKETS_TYPES_COACHES_ONLY      : "Sólo para coaches",
    TICKETS_TYPES_NONE_AVAILABLE    : "No hay tipos de consultas disponibles.",
    TICKETS_TYPES_ERROR_EXISTS      : "No existe el tipo de consulta indicado.",
    TICKETS_TYPES_ERROR_NAME        : "Debe indicar el nombre del tipo de consulta.",
    TICKETS_TYPES_ERROR_STATUS      : "Debe indicar el estado del tipo de consulta.",
    TICKETS_TYPES_ERROR_DELETE      : "No se puede eliminar el tipo de consulta indicado.",
    TICKETS_TYPES_SUCCESS_CREATE    : "El tipo de consulta se creó correctamente.",
    TICKETS_TYPES_SUCCESS_EDIT      : "El tipo de consulta se editó correctamente.",
    TICKETS_TYPES_SUCCESS_DELETE    : "El tipo de consulta se eliminó correctamente.",

    // Tickets: Support
    SUPPORT_NAME                    : "Soporte",
    SUPPORT_SINGULAR                : "Ticket",
    SUPPORT_VIEW_TITLE              : "Ver ticket",
    SUPPORT_CREATE_TITLE            : "Crear ticket",
    SUPPORT_EDIT_TITLE              : "Editar ticket",
    SUPPORT_DELETE_TITLE            : "Eliminar ticket",
    SUPPORT_DELETE_TEXT             : "¿Realmente desea eliminar el ticket <b>{0}</b>?",
    SUPPORT_OPEN_TITLE              : "Abrir ticket",
    SUPPORT_OPEN_TEXT               : "¿Realmente desea abrir el ticket <b>{0}</b>?",
    SUPPORT_OPEN_TAB                : "Tickets abiertos",
    SUPPORT_CLOSE_TITLE             : "Cerrar ticket",
    SUPPORT_CLOSE_TEXT              : "¿Realmente desea cerrar el ticket <b>{0}</b>?",
    SUPPORT_CLOSE_TAB               : "Tickets cerrados",
    SUPPORT_ASSIGN_TITLE            : "Asignar ticket",
    SUPPORT_MUTE_TITLE              : "Silenciar ticket",
    SUPPORT_UNMUTE_TITLE            : "Notificar ticket",
    SUPPORT_NONE_AVAILABLE          : "No hay tickets disponibles.",
    SUPPORT_MESSAGE                 : "Mensaje del ticket",
    SUPPORT_MESSAGE_PLACEHOLDER     : "¿Cuál fue el problema?\n\n\n¿Cuales fueron los pasos que se hicieron para generar el problema?\n\n\nIndique también alguno de estos datos (si corresponde)\nUrl:\nTramo:\nAlumno:\nCoach:\n\n¿Qué se esperaba que pase?\n\n",
    SUPPORT_IMAGE                   : "Imagen del ticket",
    SUPPORT_ERROR_EXISTS            : "No existe el ticket indicado.",
    SUPPORT_ERROR_TYPE              : "Debe indicar el tipo del ticket.",
    ACCOUNT_ERROR_IMAGE_SIZE        : "La imagen es muy grande, intente con una más pequeña.",
    ACCOUNT_ERROR_IMAGE_TYPE        : "El archivo provisto no es una imagen.",
    SUPPORT_ERROR_ADMIN             : "No existe el usuario a quien desea asignar el ticket.",
    SUPPORT_ERROR_EDIT              : "No tiene permiso para editar el ticket.",
    SUPPORT_ERROR_DELETE            : "No tiene permiso para eliminar el ticket.",
    SUPPORT_ERROR_ASSIGN            : "No tiene permiso para asignar el ticket.",
    SUPPORT_ERROR_OPEN              : "No tiene permiso para abrir el ticket.",
    SUPPORT_ERROR_CLOSE             : "No tiene permiso para cerrar el ticket.",
    SUPPORT_SUCCESS_CREATE          : "El ticket se creó correctamente.",
    SUPPORT_SUCCESS_EDIT            : "El ticket se editó correctamente.",
    SUPPORT_SUCCESS_DELETE          : "El ticket se eliminó correctamente.",
    SUPPORT_SUCCESS_ASSIGN          : "El ticket se asignó correctamente.",
    SUPPORT_SUCCESS_OPEN            : "El ticket se abrió correctamente.",
    SUPPORT_SUCCESS_CLOSE           : "El ticket se cerró correctamente.",
    SUPPORT_SUCCESS_MUTE            : "El ticket se silenció correctamente.",
    SUPPORT_SUCCESS_UNMUTE          : "El ticket se desilenció correctamente.",

    // Tickets: Support Types
    SUPPORT_TYPES_NAME              : "Tipos de tickets",
    SUPPORT_TYPES_SINGULAR          : "Tipo de ticket",
    SUPPORT_TYPES_CREATE_TITLE      : "Crear tipo de ticket",
    SUPPORT_TYPES_EDIT_TITLE        : "Editar tipo de ticket",
    SUPPORT_TYPES_DELETE_TITLE      : "Eliminar tipo de ticket",
    SUPPORT_TYPES_DELETE_TEXT       : "¿Realmente desea eliminar el tipo de ticket <b>{0}</b>?",
    SUPPORT_TYPES_ITS_NAME          : "Nombre del tipo de ticket",
    SUPPORT_TYPES_NONE_AVAILABLE    : "No hay tipos de tickets disponibles.",
    SUPPORT_TYPES_ERROR_EXISTS      : "No existe el tipo de ticket indicado.",
    SUPPORT_TYPES_ERROR_NAME        : "Debe indicar el nombre del tipo de ticket.",
    SUPPORT_TYPES_ERROR_STATUS      : "Debe indicar el estado del tipo de ticket.",
    SUPPORT_TYPES_ERROR_DELETE      : "No puede eliminar el tipo de ticket indicado.",
    SUPPORT_TYPES_SUCCESS_CREATE    : "El tipo de ticket se creó correctamente.",
    SUPPORT_TYPES_SUCCESS_EDIT      : "El tipo de ticket se editó correctamente.",
    SUPPORT_TYPES_SUCCESS_DELETE    : "El tipo de ticket se eliminó correctamente.",



    // Materials
    MATERIALS_NAME                  : "Materiales",
    MATERIALS_SINGULAR              : "Material",
    MATERIALS_VIEW_TITLE            : "Ver material",
    MATERIALS_CREATE_TITLE          : "Crear material",
    MATERIALS_ASSIGN_TITLE          : "Asignar material",
    MATERIALS_EDIT_TITLE            : "Editar material",
    MATERIALS_DELETE_TITLE          : "Eliminar material",
    MATERIALS_DELETE_TEXT           : "¿Realmente desea eliminar el material <b>{0}</b>?",
    MATERIALS_IMPORT_TITLE          : "Importar materiales",
    MATERIALS_IMPORT_TEXT           : "¿Realmente desea importar todos los materiales del programa?",
    MATERIALS_NONE_AVAILABLE        : "No hay materiales disponibles.",
    MATERIALS_COURSE                : "Curso del material",
    MATERIALS_ITS_NAME              : "Nombre del material",
    MATERIALS_CLASS                 : "Clase del material",
    MATERIALS_WELCOME               : "0 - Bienvenida",
    MATERIALS_TYPE                  : "Tipo del archivo",
    MATERIALS_TYPES                 : { 1 : "Archivo", 2 : "Video", 3 : "Vínculo" },
    MATERIALS_FILE                  : "Archivo del material",
    MATERIALS_VIDEO                 : "Video del material",
    MATERIALS_LINK                  : "Vínculo del material",
    MATERIALS_ERROR_EXISTS          : "No existe el material indicado.",
    MATERIALS_ERROR_MATERIAL        : "Debe indicar el material a asignar.",
    MATERIALS_ERROR_NAME            : "Debe indicar el nombre del material.",
    MATERIALS_ERROR_CLASS           : "Debe indicar la clase del material.",
    MATERIALS_ERROR_TYPE            : "Debe indicar el tipo del material.",
    MATERIALS_ERROR_FILE            : "Debe indicar el archivo del material.",
    MATERIALS_ERROR_TYPE_EMPTY      : "Debe indicar el tipo de archivo.",
    MATERIALS_ERROR_TYPE_INVALID    : "El tipo de archivo es incorrecto.",
    MATERIALS_ERROR_STATUS          : "Debe indicar el estado del material.",
    MATERIALS_ERROR_DATA            : "No hay materiales disponbiles para importar.",
    MATERIALS_ERROR_IMPORT          : "No hay nada nuevo para importar.",
    MATERIALS_SUCCESS_CREATE        : "El material se creó correctamente.",
    MATERIALS_SUCCESS_ASSIGN        : "El material se asignó correctamente.",
    MATERIALS_SUCCESS_EDIT          : "El material se editó correctamente.",
    MATERIALS_SUCCESS_DELETE        : "El material se eliminó correctamente.",
    MATERIALS_SUCCESS_IMPORT        : "Los materiales se importaron correctamente.",

    // Guides
    GUIDES_NAME                     : "Guías",
    GUIDES_SINGULAR                 : "Guía",
    GUIDES_DETAILS_TITLE            : "Detalles de la guía",
    GUIDES_VIEW_TITLE               : "Ver guía",
    GUIDES_CREATE_TITLE             : "Crear guía",
    GUIDES_ASSIGN_TITLE             : "Asignar guía",
    GUIDES_EDIT_TITLE               : "Editar guía",
    GUIDES_DELETE_TITLE             : "Eliminar guía",
    GUIDES_DELETE_TEXT              : "¿Realmente desea eliminar la guía <b>{0}</b>?",
    GUIDES_IMPORT_TITLE             : "Importar guías",
    GUIDES_IMPORT_TEXT              : "¿Realmente desea importar todas las guías del programa?",
    GUIDES_NONE_AVAILABLE           : "No hay guías disponibles.",
    GUIDES_NONE_ANSWERS             : "No hay respuestas disponibles.",
    GUIDES_COURSE                   : "Curso de la guia",
    GUIDES_ITS_NAME                 : "Nombre de la guía",
    GUIDES_FILE                     : "Archivo de la guía",
    GUIDES_CLASS                    : "Clase de la guía",
    GUIDES_DESCRIPTION              : "Descripción de la guía",
    GUIDES_NO_ANSWER                : "Sin respuesta",
    GUIDES_COMPLETE_HELP            : "Responda a cada una de las preguntas y vaya guardando las respuestas. Luego de contestar todas las preguntas debe enviarlas.",
    GUIDES_COMPLETE_THANKS          : "Gracias por responder las preguntas.",
    GUIDES_SENT_USER                : "{0}<br> - <b>Feedback por:</b> {1}",
    GUIDES_NO_FEEDBACK              : "Sin feedback",
    GUIDES_ERROR_EXISTS             : "No existe la guía indicada.",
    GUIDES_ERROR_GUIDE              : "Debe indicar la guía a asignar.",
    GUIDES_ERROR_COURSE             : "Debe indicar el curso de la guía.",
    GUIDES_ERROR_NAME               : "Debe indicar el nombre de la guía.",
    GUIDES_ERROR_CLASS              : "Debe indicar la clase de la guía.",
    GUIDES_ERROR_FILE               : "Debe indicar el archivo de la guía.",
    GUIDES_ERROR_STATUS             : "Debe indicar el estado de la guía.",
    GUIDES_ERROR_DATA               : "No hay guías disponbiles para importar.",
    GUIDES_ERROR_IMPORT             : "No hay nada nuevo para importar.",
    GUIDES_ERROR_ANSWER             : "Debe indicar la respuesta.",
    GUIDES_ERROR_ANSWERS_SENT       : "Las respuestas ya se enviarón.",
    GUIDES_ERROR_ANSWERS_AMOUNT     : "Debe responder todas las preguntas antes de poder enviar.",
    GUIDES_ERROR_FEEDBACK           : "Debe indicar el feedback.",
    GUIDES_ERROR_FEEDBACK_SENT      : "El feedback ya se envió.",
    GUIDES_ERROR_FEEDBACK_AMOUNT    : "Debe dar feedback a todas las secciones antes de poder enviar.",
    GUIDES_SUCCESS_CREATE           : "La guía se creó correctamente.",
    GUIDES_SUCCESS_ASSIGN           : "La guía se asignó correctamente.",
    GUIDES_SUCCESS_EDIT             : "La guía se editó correctamente.",
    GUIDES_SUCCESS_DELETE           : "La guía se eliminó correctamente.",
    GUIDES_SUCCESS_IMPORT           : "Las guías se importaron correctamente.",
    GUIDES_SUCCESS_SAVE_ANSWER      : "La respuesta se guardó correctamente.",
    GUIDES_SUCCESS_SEND_ANSWERS     : "Las respuestas se enviarón correctamente.",
    GUIDES_SUCCESS_SAVE_FEEDBACK    : "El feedback se guardó correctamente.",
    GUIDES_SUCCESS_SEND_FEEDBACK    : "El feedback se envió correctamente.",

    // Guides: Sections
    SECTIONS_NAME                   : "Secciones",
    SECTIONS_SINGULAR               : "Sección",
    SECTIONS_CREATE_TITLE           : "Crear sección",
    SECTIONS_EDIT_TITLE             : "Editar sección",
    SECTIONS_DELETE_TITLE           : "Eliminar sección",
    SECTIONS_DELETE_TEXT            : "¿Realmente desea eliminar la sección <b>{0}</b>?",
    SECTIONS_NONE_AVAILABLE         : "No hay secciones disponibles.",
    SECTIONS_ITS_NAME               : "Nombre de la sección",
    SECTIONS_PAGE                   : "Página",
    SECTIONS_ITS_PAGE               : "Página de la sección",
    SECTIONS_TYPE                   : "Forma de mostrar las preguntas",
    SECTIONS_TYPES                  : { 1 : "Reemplazar página", 2 : "Posterior a la página" },
    SECTIONS_ERROR_EXISTS           : "No existe la sección indicada.",
    SECTIONS_ERROR_NAME             : "Debe indicar el nombre de la sección.",
    SECTIONS_ERROR_TYPE_EMPTY       : "Debe indicar un forma de mostrar las preguntas.",
    SECTIONS_ERROR_TYPE_INVALID     : "La forma indicada de mostrar las preguntas es incorrecta.",
    SECTIONS_ERROR_PAGE_EMPTY       : "Debe indicar una página.",
    SECTIONS_ERROR_PAGE_INVALID     : "La página debe ser un número mayor o igual a cero.",
    SECTIONS_SUCCESS_CREATE         : "La sección se creó correctamente.",
    SECTIONS_SUCCESS_EDIT           : "La sección se editó correctamente.",
    SECTIONS_SUCCESS_DELETE         : "La sección se eliminó correctamente.",

    // Guides: Questions
    QUESTIONS_NAME                  : "Preguntas",
    QUESTIONS_SINGULAR              : "Pregunta",
    QUESTIONS_CREATE_TITLE          : "Crear pregunta",
    QUESTIONS_EDIT_TITLE            : "Editar pregunta",
    QUESTIONS_DELETE_TITLE          : "Eliminar pregunta",
    QUESTIONS_DELETE_TEXT           : "¿Realmente desea eliminar la pregunta <b>{0}</b>?",
    QUESTIONS_IMPORT_TITLE          : "Importar preguntas",
    QUESTIONS_IMPORT_TEXT           : "¿Realmente desea importar todas las preguntas del programa?",
    QUESTIONS_NEW_QUESTION          : "Nueva pregunta",
    QUESTIONS_NEW_HELP              : "Puede crear una pregunta a paritir de una predefinada o crear una nueva.",
    QUESTIONS_TYPE                  : "Tipo de respuesta",
    QUESTIONS_TYPES                 : { "text" : "Texto", "textarea" : "Texto largo", "radio" : "Opciones" },
    QUESTIONS_HELP_TEXT             : "Texto de ayuda",
    QUESTIONS_OPTIONS               : "Opciones para la respuesta",
    QUESTIONS_WITH_OTHER            : "Agregar una opción otro personalizable",
    QUESTIONS_REQUIRED              : "Requerida",
    QUESTIONS_OPTIONAL              : "Opcional",
    QUESTIONS_IS_REQUIRED           : "La respuesta es requerida",
    QUESTIONS_ACCESS_TYPE           : "Acceso para ver la pregunta",
    QUESTIONS_ACCESS_TYPES          : { 1 : "Todos", 2 : "Alumnos", 3 : "Coaches" },
    QUESTIONS_ANSWER                : "Respuesta a la pregunta",
    QUESTIONS_VIDEO                 : "Video de YouTube como respuesta",
    QUESTIONS_NONE_AVAILABLE        : "No hay preguntas disponibles.",
    QUESTIONS_ERROR_EXISTS          : "No existe la pregunta indicada.",
    QUESTIONS_ERROR_QUESTION        : "Debe indicar la pregunta.",
    QUESTIONS_ERROR_TYPE_EMPTY      : "Debe indicar el tipo de la pregunta.",
    QUESTIONS_ERROR_TYPE_INV        : "El tipo de la pregunta indicado es incorrecto.",
    QUESTIONS_ERROR_OPTIONS         : "Debe indicar las opciones de la pregunta.",
    QUESTIONS_ERROR_SECTION         : "Debe indicar la sección de la pregunta.",
    QUESTIONS_ERROR_STATUS          : "Debe indicar el estado de la pregunta.",
    QUESTIONS_ERROR_DATA            : "No hay preguntas disponbiles para importar.",
    QUESTIONS_ERROR_IMPORT          : "No hay nada nuevo para importar.",
    QUESTIONS_ERROR_ANSWER          : "Debe indicar la respuesta.",
    QUESTIONS_ERROR_ACCESS_EMPTY    : "Debe indicar el accesso de la pregunta.",
    QUESTIONS_ERROR_ACCESS_INVALID  : "El accesso indicado es incorrecto.",
    QUESTIONS_SUCCESS_CREATE        : "La pregunta se creó correctamente.",
    QUESTIONS_SUCCESS_EDIT          : "La pregunta se editó correctamente.",
    QUESTIONS_SUCCESS_DELETE        : "La pregunta se eliminó correctamente.",
    QUESTIONS_SUCCESS_IMPORT        : "Las preguntas se importaron correctamente.",



    // Locations
    LOCATIONS_NAME                  : "Lugares",
    LOCATIONS_SINGULAR              : "Lugar",
    LOCATIONS_DETAILS_TITLE         : "Detalles del lugar",
    LOCATIONS_CREATE_TITLE          : "Crear lugar",
    LOCATIONS_EDIT_TITLE            : "Editar lugar",
    LOCATIONS_VIEW_TITLE            : "Ver lugar",
    LOCATIONS_DELETE_TITLE          : "Eliminar lugar",
    LOCATIONS_DELETE_TEXT           : "¿Realmente desea eliminar el lugar <b>{0}</b>?",
    LOCATIONS_NONE_AVAILABLE        : "No hay lugares disponibles.",
    LOCATIONS_ITS_NAME              : "Nombre del lugar",
    LOCATIONS_ADDRESS               : "Dirección del lugar",
    LOCATIONS_LOCALITY              : "Localidad del lugar",
    LOCATIONS_PROVINCE              : "Provincia del lugar",
    LOCATIONS_ZIP_CODE              : "Código postal del lugar",
    LOCATIONS_CONTACT               : "Nombre del contacto",
    LOCATIONS_PHONE                 : "Teléfono/celular del contacto",
    LOCATIONS_FOR_STRECHES          : "¿Se puede usar para tramos?",
    LOCATIONS_FOR_CONVERSATIONS     : "¿Se puede usar para conversaciones?",
    LOCATIONS_FOR_CONVERSATIONS_TXT : "Para conversaciones",
    LOCATIONS_FOR_MEETINGS          : "¿Se puede usar para reuniones de grupo?",
    LOCATIONS_FOR_MEETINGS_TXT      : "Para reuniones",
    LOCATIONS_ERROR_EXISTS          : "No existe el lugar indicado.",
    LOCATIONS_ERROR_NAME            : "Debe indicar el nombrel del lugar.",
    LOCATIONS_ERROR_ADDRESS         : "Debe indicar la dirección del lugar.",
    LOCATIONS_ERROR_LOCALITY        : "Debe indicar la localidad del lugar.",
    LOCATIONS_ERROR_PROVINCE        : "Debe indicar la provincia del lugar.",
    LOCATIONS_ERROR_COUNTRY         : "Debe indicar el país del lugar.",
    LOCATIONS_ERROR_STATUS          : "Debe indicar el estado del lugar.",
    LOCATIONS_SUCCESS_CREATE        : "El lugar se creó correctamente.",
    LOCATIONS_SUCCESS_EDIT          : "El lugar se editó correctamente.",
    LOCATIONS_SUCCESS_DELETE        : "El lugar se eliminó correctamente.",

    // Locations: Rooms
    ROOMS_NAME                      : "Salones",
    ROOMS_SINGULAR                  : "Salón",
    ROOMS_CREATE_TITLE              : "Crear salón",
    ROOMS_EDIT_TITLE                : "Editar salón",
    ROOMS_DELETE_TITLE              : "Eliminar salón",
    ROOMS_DELETE_TEXT               : "¿Realmente desea eliminar el salón <b>{0}</b>?",
    ROOMS_NONE_AVAILABLE            : "No hay salóns disponibles.",
    ROOMS_ITS_NAME                  : "Nombre del salón",
    ROOMS_FLOOR                     : "Piso",
    ROOMS_FLOOR_NAME                : "Piso {0}",
    ROOMS_ITS_FLOOR                 : "Piso del salón",
    ROOMS_ERROR_EXISTS              : "No existe el salón indicado.",
    ROOMS_ERROR_NAME                : "Debe indicar el nombre del salón.",
    ROOMS_ERROR_FLOOR               : "El piso debe ser un número mayor o igual a cero.",
    ROOMS_SUCCESS_CREATE            : "El salón se creó correctamente.",
    ROOMS_SUCCESS_EDIT              : "El salón se editó correctamente.",
    ROOMS_SUCCESS_DELETE            : "El salón se eliminó correctamente.",



    // Users
    USERS_NAME                      : "Usuarios",
    USERS_SINGULAR                  : "Usuario",
    USERS_VIEW_TITLE                : "Ver usuario",
    USERS_SEARCH_TITLE              : "Buscar usuario",
    USERS_LEVEL                     : "Nivel de acceso",
    USERS_FIRST_NAME                : "Nombre",
    USERS_LAST_NAME                 : "Apellido",
    USERS_NICK_NAME                 : "Apodo",
    USERS_NEW_PASSWORD              : "Nueva contraseña",
    USERS_PASSWORD                  : "Contraseña",
    USERS_PASSWORD_CONFIRM          : "Confirmar contraseña",
    USERS_CELLPHONE                 : "Celular/WhatsApp",
    USERS_IDD                       : "Código",
    USERS_NATIONALITY               : "Nacionalidad",
    USERS_DNI                       : "DNI",
    USERS_CUIT                      : "CUIT",
    USERS_TAX_ID                    : "Número de identificación",
    USERS_COUNTRY                   : "País de residencia",
    USERS_OCUPATION                 : "Ocupación",
    USERS_SCHEDULE                  : "Horario disponible",
    USERS_DOCUMENT                  : "Documento",
    USERS_DOCUMENTS                 : { 1 : "DNI", 2 : "CUIT" },
    USERS_GENDER                    : "Género",
    USERS_GENDERS                   : { 1 : "Masculino", 2 : "Femenina", 3 : "Otro" },
    USERS_TRAFFICLIGHT              : "Semaforo",
    USERS_TRAFFICLIGHTS             : { 1 : "Verde", 2 : "Amarillo", 3 : "Rojo" },
    USERS_CAMADANOM                 : 'Camada',
    USERS_CAMADA                    : { 2013 : "2013", 2014 : "2014", 2015 : "2015", 2016 : "2016", 2017 : "2017", 2018 : "2018", 2019 : "2019", 2020 : "2020", 2021 : "2021", 2022 : "2022", 2023 : "2023", 2024 : "2024", 2025 : "2025", 2026 : "2026", 2027 : "2027", 2028 : "2028", 2029 : "2029" },
    USERS_PROFESION                 : "Profesión",
    USERS_PROFESIONS                : { 1 : "Contador", 2 : "abogado", 3 : "otro" },
    USERS_INITIAL_CONVERSATIONS     : "Conversaciones iniciales",
    USERS_SEND_EMAIL_NOTIS          : "Enviar notificaciones por email de clases y charlas",
    USERS_SEND_WHATSAPP_NOTIS       : "Enviar notificaciones por whatsapp de clases y charlas",
    USERS_SEND_COACH_ENROLLS        : "Enviar aviso para inscribirse como coach en un tramo",
    USERS_AFIP                      : "AFIP",
    USERS_AFIP_INSCRIPTION          : "Inscripción en la AFIP",
    USERS_AFIP_OPTIONS              : { 1 : "No inscripto en la AFIP", 2 : "Inscripto en la AFIP" },
    USERS_AFIP_MINE                 : { 1 : "No estoy inscripto", 2 : "Estoy inscripto" },
    USERS_AFIP_SHORT                : { 1 : "No", 2 : "Si" },
    USERS_SCHOOL_STAFF              : "Parte de la Escuela",
    USERS_CAN_CONVERSE              : "Puede tener conversaciones de coaching",
    USERS_CAN_CONVERSE_SHORT        : "Tiene conversaciones",
    USERS_SHOW_IN_SITE              : "Mostrar en el Sitio",
    USERS_SEND_EMAILS               : "Enviar Emails",
    USERS_SEND_TICKETS              : "Enviar nuevas Consultas",
    USERS_FOR_SUPPORT               : "Administra tickets de soporte",
    USERS_FOR_LEADS                 : "Gestiona leads",
    USERS_NONE_AVAILABLE            : "No hay usuarios disponibles",
    USERS_ERROR_EXISTS              : "No existe el usuario indicado.",
    USERS_ERROR_LEVEL_EMPTY         : "Debe indicar un nivel de acceso.",
    USERS_ERROR_LEVEL_INVALID       : "El nivel de acceso indicado es inválido.",
    USERS_ERROR_FIRST_NAME          : "Debe indicar un nombre.",
    USERS_ERROR_LAST_NAME           : "Debe indicar un apellido.",
    USERS_ERROR_NATIONALITY         : "Debe indicar una nacionalidad.",
    USERS_ERROR_DOCUMENT_EMPTY      : "Debe indicar un documento.",
    USERS_ERROR_DOCUMENT_INVALID    : "El documento indicado es incorrecto.",
    USERS_ERROR_DNI_EMPTY           : "Debe indicar un DNI.",
    USERS_ERROR_DNI_INVALID         : "El DNI indicado es incorrecto.",
    USERS_ERROR_DNI_USED            : "El DNI indicado esta siendo usado por otra cuenta.",
    USERS_ERROR_CUIT_EMPTY          : "Debe indicar un CUIT.",
    USERS_ERROR_CUIT_INVALID        : "El CUIT indicado es incorrecto.",
    USERS_ERROR_CUIT_USED           : "El CUIT indicado esta siendo usado por otra cuenta.",
    USERS_ERROR_TAXID_EMPTY         : "Debe indicar un número de identificación.",
    USERS_ERROR_CELLPHONE_EMPTY     : "Debe indicar un celular.",
    USERS_ERROR_CELLPHONE_INVALID   : "El celular debe ser numérico.",
    USERS_ERROR_IDD_EMPTY           : "Debe indicar el código para el celular.",
    USERS_ERROR_IDD_INVALID         : "El código para el celular indicado no existe.",
    USERS_ERROR_COUNTRY             : "Debe indicar un país de residencia.",
    USERS_ERROR_PROVINCE            : "Debe indicar una provincia.",
    USERS_ERROR_BIRTHDATE_EMPTY     : "Debe indicar una fecha de nacimiento.",
    USERS_ERROR_BIRTHDATE_INVALID   : "La fecha de nacimiento es incorrecta.",
    USERS_ERROR_AFIP_EMPTY          : "Debe indicar la inscripción en la AFIP.",
    USERS_ERROR_AFIP_INVALID        : "La inscripción en la AFIP es incorrecta.",
    USERS_ERROR_GENDER_EMPTY        : "Debe indicar un género.",
    USERS_ERROR_GENDER_INVALID      : "El género indicado es incorrecto.",
    USERS_ERROR_EMAIL_EMPTY         : "Debe indicar un email.",
    USERS_ERROR_EMAIL_INVALID       : "El email indicado es incorrecto.",
    USERS_ERROR_EMAIL_USED          : "El email indicado esta siendo usado por otra cuenta.",
    USERS_ERROR_PASSWORD_EMPTY      : "Debe indicar una contraseña.",
    USERS_ERROR_PASSWORD_INVALID    : "La contraseña debe contener 6 o más caracteres y al menos un número y una letra.",
    USERS_ERROR_PASSWORD_CONFIRM    : "Debe confirmar la nueva contraseña.",
    USERS_ERROR_PASSWORD_MATCH      : "Las contraseñas son diferentes.",

    // Users: Students
    STUDENTS_NAME                   : "Alumnos",
    STUDENTS_SIGNUPS                : "Últimos registros",
    STUDENTS_SINGULAR               : "Alumno",
    STUDENTS_AS_TITLE               : "Como alumno",
    STUDENTS_FILTER_TITLE           : "Filtrar alumnos",
    STUDENTS_DETAILS_TITLE          : "Detalles del alumno",
    STUDENTS_VIEW_TITLE             : "Ver alumno",
    STUDENTS_EMAIL_TITLE            : "Enviar Email",
    STUDENTS_WHATSAPP_TITLE         : "Enviar WhatsApp",
    STUDENTS_CREATE_TITLE           : "Crear alumno",
    STUDENTS_EDIT_TITLE             : "Editar alumno",
    STUDENTS_ACCESS_TITLE           : "Cambiar a coach",
    STUDENTS_ACCESS_TEXT            : "¿Realmente desea cambiar el alumno <b>{0}</b> a coach?",
    STUDENTS_DELETE_TITLE           : "Eliminar alumno",
    STUDENTS_DELETE_TEXT            : "¿Realmente desea eliminar el alumno <b>{0}</b>?",
    STUDENTS_LOGIN_AS               : "Ingresar como alumno",
    STUDENTS_NONE_AVAILABLE         : "No hay alumnos disponibles.",
    STUDENTS_NONE_SIGNUPS           : "No hay registros disponibles.",
    STUDENTS_ERROR_EXISTS           : "No existe el alumno indicado.",
    STUDENTS_ERROR_COURSES          : "El alumno no completó los cursos para ser coach.",
    STUDENTS_ERROR_ACTIVE           : "El alumno no es un alumno activo.",
    STUDENTS_ERROR_DATA             : "El alumno no tiene los datos completos.",
    STUDENTS_SUCCESS_CREATE         : "El alumno se creó correctamente.",
    STUDENTS_SUCCESS_EDIT           : "El alumno se editó correctamente.",
    STUDENTS_SUCCESS_EDIT_LEVEL     : "El alumno se cambió a coach correctamente.",
    STUDENTS_SUCCESS_DELETE         : "El alumno se eliminó correctamente.",

    // Users: Coaches
    COACHES_NAME                    : "Coaches",
    COACHES_SINGULAR                : "Coach",
    COACHES_AS_TITLE                : "Como coach",
    COACHES_FILTER_TITLE            : "Filtrar coaches",
    COACHES_DETAILS_TITLE           : "Detalles del coach",
    COACHES_VIEW_TITLE              : "Ver coach",
    COACHES_CREATE_TITLE            : "Crear coach",
    COACHES_ASSIGN_TITLE            : "Asignar coach",
    COACHES_EDIT_TITLE              : "Editar coach",
    COACHES_ACCESS_TITLE            : "Cambiar a alumno",
    COACHES_ACCESS_TEXT             : "¿Realmente desea cambiar el coach <b>{0}</b> a alumno?",
    COACHES_DELETE_TITLE            : "Eliminar coach",
    COACHES_DELETE_TEXT             : "¿Realmente desea eliminar el coach <b>{0}</b>?",
    COACHES_LOGIN_AS                : "Ingresar como coach",
    COACHES_NONE_AVAILABLE          : "No hay coaches disponibles.",
    COACHES_TYPE                    : "Tipo de coach",
    COACHES_TYPES                   : { 1 : "Facilitador", 2 : "Ayudante" },
    COACHES_PRICE                   : "Honorarios por el tramo",
    COACHES_CAN_ASSIGN              : "¿Se le puede asignar alumnos?",
    COACHES_HAS_ASSIGN              : "Asignar",
    COACHES_ERROR_EXISTS            : "No existe el coach indicado.",
    COACHES_ERROR_EMPTY             : "Debe indicar el coach.",
    COACHES_ERROR_USED              : "Ya existe el coach indicado.",
    COACHES_ERROR_CAN_CONVERSE      : "El coach no puede tener conversaciones.",
    COACHES_ERROR_TYPE_EMPTY        : "Debe indicar el tipo de coach.",
    COACHES_ERROR_TYPE_INVALID      : "El tipo de coach es inválido.",
    COACHES_ERROR_STATUS            : "Debe indicar el estado del coach.",
    COACHES_SUCCESS_CREATE          : "El coach se creó correctamente.",
    COACHES_SUCCESS_EDIT            : "El coach se editó correctamente.",
    COACHES_SUCCESS_EDIT_LEVEL      : "El coach se cambió a alumno correctamente.",
    COACHES_SUCCESS_DELETE          : "El coach se eliminó correctamente.",

    // Users: Admins
    ADMINS_NAME                     : "Administradores",
    ADMINS_SINGULAR                 : "Administrador",
    ADMINS_CREATE_TITLE             : "Crear administrador",
    ADMINS_EDIT_TITLE               : "Editar administrador",
    ADMINS_DELETE_TITLE             : "Eliminar administrador",
    ADMINS_DELETE_TEXT              : "¿Realmente desea eliminar el administrador <b>{0}</b>?",
    ADMINS_NONE_AVAILABLE           : "No hay administradores disponibles.",
    ADMINS_ERROR_EXISTS             : "No existe el administrador indicado.",
    ADMINS_SUCCESS_CREATE           : "El administrador se creó correctamente.",
    ADMINS_SUCCESS_EDIT             : "El administrador se editó correctamente.",
    ADMINS_SUCCESS_DELETE           : "El administrador se eliminó correctamente.",



    // Coach: Prices
    COACH_PRICES_NAME               : "Honorario por especialidad",
    COACH_PRICES_HELP               : "Indique los precios de <b>{0}</b> por cada especialidad:",
    COACH_PRICES_ERROR_USER         : "No existe el coach indicado.",
    COACH_PRICES_ERROR_EMPTY        : "Debe indicar los precios del coach.",
    COACH_PRICES_ERROR_SPECIALITY   : "Una de las especialidades indicadas no existe.",
    COACH_PRICES_SUCCESS            : "Los precios se editaron correctamente.",

    // Coach: Specialities
    SPECIALITIES_NAME               : "Especialidades de coaches",
    SPECIALITIES_SINGULAR           : "Especialidad de coach",
    SPECIALITIES_TITLE              : "Especialidades",
    SPECIALITIES_SHORT              : "Especialidad",
    SPECIALITIES_CREATE_TITLE       : "Crear especialidad de coach",
    SPECIALITIES_ASSIGN_TITLE       : "Asignar especialidad de coach",
    SPECIALITIES_EDIT_TITLE         : "Editar especialidad de coach",
    SPECIALITIES_DELETE_TITLE       : "Eliminar especialidad de coach",
    SPECIALITIES_DELETE_TEXT        : "¿Realmente desea eliminar la especialidad de coach <b>{0}</b>?",
    SPECIALITIES_IMPORT_TITLE       : "Importar especialidades",
    SPECIALITIES_IMPORT_TEXT        : "¿Realmente desea importar todas las especialidades del programa?",
    SPECIALITIES_ITS_NAME           : "Nombre de la especialidad",
    SPECIALITIES_PRICE_TYPE         : "Tipo de precio usado",
    SPECIALITIES_PRICE_TYPES        : { 1 : "Usando Categorías", 2 : "Por Coach", 3 : "Genérico" },
    SPECIALITIES_GENERIC_PRICE      : "Precio genérico",
    SPECIALITIES_MAX_AMOUNT         : "Máxima cantidad de conversaciones",
    SPECIALITIES_NONE_AVAILABLE     : "No hay especialidades de coaches disponibles.",
    SPECIALITIES_ERROR_EXISTS       : "No existe la especialidad de coach indicada.",
    SPECIALITIES_ERROR_SPECIALITY   : "Debe indicar la especialidad a asignar.",
    SPECIALITIES_ERROR_MAX_AMOUNT   : "La máxima cantidad debe ser un número mayor a 0.",
    SPECIALITIES_ERROR_MAX_VALUE    : "La máxima cantidad debe igual o menor a {0}.",
    SPECIALITIES_ERROR_REPEATED     : "No puede repetir las especialidades a asignar.",
    SPECIALITIES_ERROR_NAME         : "Debe indicar el nombre de la especialidad.",
    SPECIALITIES_ERROR_PRICE_EMPTY  : "Debe indicar el tipo de precio a usar.",
    SPECIALITIES_ERROR_PRICE_INV    : "No existe el tipo de precio indicado.",
    SPECIALITIES_ERROR_GENERIC      : "Debe indicar el precio genérico para el tipo de precio indicado.",
    SPECIALITIES_ERROR_STATUS       : "Debe indicar el estado de la especialidad.",
    SPECIALITIES_ERROR_DATA         : "No hay especialidades para importar.",
    SPECIALITIES_ERROR_IMPORT       : "No hay nada nuevo para importar.",
    SPECIALITIES_SUCCESS_CREATE     : "La especialidad de coach se creó correctamente.",
    SPECIALITIES_SUCCESS_ASSIGN     : "La especialidad de coach se asignó correctamente.",
    SPECIALITIES_SUCCESS_EDIT       : "La especialidad de coach se editó correctamente.",
    SPECIALITIES_SUCCESS_DELETE     : "La especialidad de coach se eliminó correctamente.",
    SPECIALITIES_SUCCESS_IMPORT     : "Las especialidades se importaron correctamente.",

    // Coach: Categories
    COACH_CATEGORIES_NAME           : "Categorías de coaches",
    COACH_CATEGORIES_SINGULAR       : "Categoría de coach",
    COACH_CATEGORIES_CREATE_TITLE   : "Crear categoría de coach",
    COACH_CATEGORIES_EDIT_TITLE     : "Editar categoría de coach",
    COACH_CATEGORIES_DELETE_TITLE   : "Eliminar categoría de coach",
    COACH_CATEGORIES_DELETE_TEXT    : "¿Realmente desea eliminar la categoría de coach <b>{0}</b>?",
    COACH_CATEGORIES_ITS_NAME       : "Nombre de la categoría de coach",
    COACH_CATEGORIES_FROM           : "Desde cuantas conversaciones reportadas",
    COACH_CATEGORIES_PRICE          : "Precio a pagar por conversación reportada",
    COACH_CATEGORIES_SCHOOL         : "Para escuela",
    COACH_CATEGORIES_SCHOOL_PRICE   : "Precio a pagar a integrantes de la escuela",
    COACH_CATEGORIES_SCHOOL_TIP     : "Si se deja en blanco se usa el precio de arriba",
    COACH_CATEGORIES_QUOTA          : "Porcentaje de coaches a asignar automáticamente",
    COACH_CATEGORIES_NONE_AVAILABLE : "No hay categorías de coaches disponibles.",
    COACH_CATEGORIES_ERROR_EXISTS   : "No existe la categoría de coach indicada.",
    COACH_CATEGORIES_ERROR_NAME     : "Debe indicar el nombre de la categoría de coach.",
    COACH_CATEGORIES_ERROR_FROM     : "El desde conversaciones debe ser un número mayor o igual a 0.",
    COACH_CATEGORIES_ERROR_PRICE    : "El precio por conversación de la categoría coach es incorrecto.",
    COACH_CATEGORIES_ERROR_SCHOOL   : "El precio para integrantes de la escuela es incorrecto.",
    COACH_CATEGORIES_ERROR_QUOTA    : "El porcentaje debe ser un valor entre 1 y 100.",
    COACH_CATEGORIES_ERROR_STATUS   : "Debe indicar el estado de la categoría de coach.",
    COACH_CATEGORIES_SUCCESS_CREATE : "La categoría de coach se creó correctamente.",
    COACH_CATEGORIES_SUCCESS_EDIT   : "La categoría de coach se editó correctamente.",
    COACH_CATEGORIES_SUCCESS_DELETE : "La categoría de coach se eliminó correctamente.",

    // Coach: USERS_PROFESIONS  
    COACH_PROFESION_NAME           : "Profesiones de coaches",
    COACH_PROFESION_SINGULAR       : "Profesión de coach",
    COACH_PROFESION_CREATE_TITLE   : "Crear profesión de coach",
    COACH_PROFESION_EDIT_TITLE     : "Editar profesión de coach",
    COACH_PROFESION_DELETE_TITLE   : "Eliminar profesión de coach",
    COACH_PROFESION_DELETE_TEXT    : "¿Realmente desea eliminar la profesión de coach <b>{0}</b>?",
    COACH_PROFESION_ITS_NAME       : "Nombre de la profesión de coach",
    COACH_PROFESION_FROM           : "Desde cuantas conversaciones reportadas",
    COACH_PROFESION_NONE_AVAILABLE : "No hay profesión de coaches disponibles.",
    COACH_PROFESION_SUCCESS_CREATE : "La profesión de coach se creó correctamente.",
    COACH_PROFESION_SUCCESS_EDIT   : "La profesión de coach se editó correctamente.",
    COACH_PROFESION_SUCCESS_DELETE : "La profesión de coach se eliminó correctamente.",




    // Coach: Questions
    COACH_QUESTIONS_NAME            : "Preguntas de reportes",
    COACH_QUESTIONS_SINGULAR        : "Pregunta de reporte",
    COACH_QUESTIONS_TYPES           : { "textarea" : "Texto", "select" : "Opciones" },
    COACH_QUESTIONS_ERROR_SECTION   : "La sección indicada no existe.",

    // Coach: Invoices
    COACH_INVOICES_NAME             : "Facturas",
    COACH_INVOICES_SINGULAR         : "Factura",
    COACH_INVOICES_FILTER_TITLE     : "Filtrar facturas",
    COACH_INVOICES_VIEW_TITLE       : "Ver detalles",
    COACH_INVOICES_DOWNLOAD_TITLE   : "Descargar factura",
    COACH_INVOICES_CREATE_TITLE     : "Enviar factura",
    COACH_INVOICES_SEND_TITLE       : "Enviar factura",
    COACH_INVOICES_ATTACH_TITLE     : "Adjuntar factura",
    COACH_INVOICES_APPROVE_TITLE    : "Aprobar factura",
    COACH_INVOICES_APPROVE_TEXT     : "¿Realmente desea aprobar la factura indicada? Luego de rechazarla se puede volver a enviar la factura.",
    COACH_INVOICES_REJECT_TITLE     : "Rechazar factura",
    COACH_INVOICES_REJECT_TEXT      : "¿Realmente desea rechazar la factura indicada? Luego de rechazarla se puede volver a enviar la factura.",
    COACH_INVOICES_REJECT_REASON    : "Motivo del rechazo:",
    COACH_INVOICES_PAID_TITLE       : "Marcar como pagada",
    COACH_INVOICES_PAID_TEXT        : "¿Realmente desea marcar la factura indicada como pagada?",
    COACH_INVOICES_WITH             : "Elementos a facturar del tramo:",
    COACH_INVOICES_WITHOUT          : "Conversaciones a facturar sin tramo:",
    COACH_INVOICES_EXTRA            : "Sin tramo",
    COACH_INVOICES_NOT_APPLY        : "No aplica",
    COACH_INVOICES_STRECH           : "Honorarios por el tramo",
    COACH_INVOICES_CONVERSATION1    : "Conversación Nº <b>{0}</b> con <b>{1}</b> el <i>{2}</i>",
    COACH_INVOICES_CONVERSATION2    : "Conversación con <b>{0}</b> el <i>{1}</i>",
    COACH_INVOICES_MEETING          : "Reunión del grupo <b>{0}</b> el <i>{1}</i>",
    COACH_INVOICES_FILE             : "Archivo de la factura",
    COACH_INVOICES_MESSAGE          : "Puede dejar un mensaje",
    COACH_INVOICES_STATUS           : "{0} - Por el motivo: {1}.",
    COACH_INVOICES_NONE_TO_INVOICE  : "No hay conversaciones para facturar.",
    COACH_INVOICES_NONE_AVAILABLE   : "No hay facturas disponibles.",
    COACH_INVOICES_ERROR_EXISTS     : "No existe la factura indicada.",
    COACH_INVOICES_ERROR_CREATE     : "No hay conversaciones para facturar.",
    COACH_INVOICES_ERROR_FILE_EMPTY : "Debe proporcionar el archivo de la factura.",
    COACH_INVOICES_ERROR_FILE_SIZE  : "El archivo es muy grande, intente con una más pequeña.",
    COACH_INVOICES_ERROR_FILE_TYPE  : "El archivo provisto no es un pdf.",
    COACH_INVOICES_ERROR_SEND       : "No puede enviar la factura indicada.",
    COACH_INVOICES_ERROR_APPROVE    : "No puede marcar la factura indicada como aprobada.",
    COACH_INVOICES_ERROR_REJECT     : "No puede marcar la factura indicada como rechazada.",
    COACH_INVOICES_ERROR_PAID       : "No puede marcar la factura indicada como pagada.",
    COACH_INVOICES_SUCCESS_CREATE   : "La factura se creó correctamente.",
    COACH_INVOICES_SUCCESS_SEND     : "La factura se envió correctamente.",
    COACH_INVOICES_SUCCESS_ATTACH   : "La factura se adjuntó correctamente.",
    COACH_INVOICES_SUCCESS_APPROVED : "La factura se marcó como aprobada correctamente.",
    COACH_INVOICES_SUCCESS_REJECTED : "La factura se marcó como rechazada correctamente.",
    COACH_INVOICES_SUCCESS_PAID     : "La factura se marcó como pagada correctamente.",

    // Coach: Materials
    COACH_MATERIALS_NAME            : "Materiales para coaches",
    COACH_MATERIALS_SINGULAR        : "Material para coach",
    COACH_MATERIALS_LIBRARY         : "Biblioteca",
    COACH_MATERIALS_CREATE_TITLE    : "Crear material para coach",
    COACH_MATERIALS_EDIT_TITLE      : "Editar material para coach",
    COACH_MATERIALS_DELETE_TITLE    : "Eliminar material para coach",
    COACH_MATERIALS_DELETE_TEXT     : "¿Realmente desea eliminar el material para coach <b>{0}</b>?",
    COACH_MATERIALS_ITS_NAME        : "Nombre del material para coach",
    COACH_MATERIALS_NONE_AVAILABLE  : "No hay materiales para coaches disponibles.",
    COACH_MATERIALS_ERROR_EXISTS    : "No existe el material para coach indicado.",
    COACH_MATERIALS_SUCCESS_CREATE  : "El material para coach se creó correctamente.",
    COACH_MATERIALS_SUCCESS_EDIT    : "El material para coach se editó correctamente.",
    COACH_MATERIALS_SUCCESS_DELETE  : "El material para coach se eliminó correctamente.",



    // Setup
    SETTINGS_NAME                   : "Configuración",
    SETTINGS_TITLE                  : "Configuración del Sistema",
    SETTINGS_GENERAL                : "General",
    SETTINGS_LOCATION               : "Ubicación",
    SETTINGS_EMAILS                 : "Emails",
    SETTINGS_EXTRAS                 : "Extras",

    // Setup: grupo o madre Interests
    GROUPINTERESTS_NAME                  : "Grupo de Intereses",
    GROUPINTERESTS_SINGULAR              : "Grupo Interés",
    GROUPINTERESTS_CREATE_TITLE          : "Crear Grupo de interés",
    GROUPINTERESTS_EDIT_TITLE            : "Editar Grupo de interés",
    GROUPINTERESTS_DELETE_TITLE          : "Eliminar Grupo de interés",
    GROUPINTERESTS_DELETE_TEXT           : "¿Realmente desea eliminar el grupo interés <b>{0}</b>?",
    GROUPINTERESTS_ITS_NAME              : "Nombre del grupo de interés",
    GROUPINTERESTS_NONE_AVAILABLE        : "No hay grupos de intereses disponibles.",
    GROUPINTERESTS_ERROR_EXISTS          : "No existe el grupo de interés indicado.",
    GROUPINTERESTS_ERROR_NAME            : "Debe indicar el nombre del grupo de interés.",
    GROUPINTERESTS_ERROR_STATUS          : "Debe indicar el estado del grupo de interés.",
    GROUPINTERESTS_SUCCESS_CREATE        : "El grupo de interés se creó correctamente.",
    GROUPINTERESTS_SUCCESS_EDIT          : "El grupo de interés se editó correctamente.",
    GROUPINTERESTS_SUCCESS_DELETE        : "El grupo de interés se eliminó correctamente.",


    // Setup: Interests
    INTERESTS_NAME                  : "Intereses",
    INTERESTS_SINGULAR              : "Interés",
    INTERESTS_CREATE_TITLE          : "Crear interés",
    INTERESTS_EDIT_TITLE            : "Editar interés",
    INTERESTS_DELETE_TITLE          : "Eliminar interés",
    INTERESTS_DELETE_TEXT           : "¿Realmente desea eliminar el interés <b>{0}</b>?",
    INTERESTS_ITS_NAME              : "Nombre del interés",
    INTERESTS_COURSE                : "Curso asociado al interés",
    INTERESTS_DESCRIPTION           : "Descripción del interés",
    INTERESTS_EMAIL_TEXT            : "Texto para mostrar en el email enviado desde el landing",
    INTERESTS_ACTIVITY              : "Actividad",
    INTERESTS_IS_ACTIVITY           : "¿Es una actividad mostrada en la web?",
    INTERESTS_HIDE_LINK             : "Ocultar el link de inscripción en el email si no hay cursos disponibles",
    INTERESTS_NONE_AVAILABLE        : "No hay intereses disponibles.",
    INTERESTS_ERROR_EXISTS          : "No existe el interés indicado.",
    INTERESTS_ERROR_NAME            : "Debe indicar el nombre del interés.",
    INTERESTS_ERROR_DESCRIPTION     : "Debe indicar la descripción del interés al ser una actividad.",
    INTERESTS_ERROR_STATUS          : "Debe indicar el estado del interés.",
    INTERESTS_SUCCESS_CREATE        : "El interés se creó correctamente.",
    INTERESTS_SUCCESS_EDIT          : "El interés se editó correctamente.",
    INTERESTS_SUCCESS_DELETE        : "El interés se eliminó correctamente.",

    // Setup: Staff
    STAFF_NAME                      : "Quienes somos",
    STAFF_SINGULAR                  : "Miembro",
    STAFF_CREATE_TITLE              : "Crear miembro",
    STAFF_EDIT_TITLE                : "Editar miembro",
    STAFF_DELETE_TITLE              : "Eliminar miembro",
    STAFF_DELETE_TEXT               : "¿Realmente desea eliminar el miembro <b>{0}</b>?",
    STAFF_ITS_NAME                  : "Nombre del miembro",
    STAFF_COACH                     : "Coach asociado al miembro",
    STAFF_SUMMARY                   : "Resumen de la biografía",
    STAFF_BIOGRAPHY                 : "Biografía",
    STAFF_ITS_BIOGRAPHY             : "Biografía del miembro",
    STAFF_IMAGE                     : "Imagen del miembro (180px x 180px)",
    STAFF_NONE_AVAILABLE            : "No hay miembros disponibles.",
    STAFF_ERROR_EXISTS              : "No existe el miembro indicado.",
    STAFF_ERROR_NAME                : "Debe indicar el nombre del miembro.",
    STAFF_ERROR_SUMMARY             : "Debe indicar el resumen de la biografía.",
    STAFF_ERROR_BIOGRAPHY           : "Debe indicar la biografía del miembro.",
    STAFF_ERROR_IMAGE               : "Debe indicar la imagen del miembro.",
    STAFF_ERROR_STATUS              : "Debe indicar el estado del miembro.",
    STAFF_SUCCESS_CREATE            : "El miembro se creó correctamente.",
    STAFF_SUCCESS_EDIT              : "El miembro se editó correctamente.",
    STAFF_SUCCESS_DELETE            : "El miembro se eliminó correctamente.",

    // Setup: News
    NEWS_NAME                       : "Novedades",
    NEWS_SINGULAR                   : "Novedad",
    NEWS_CREATE_TITLE               : "Crear novedad",
    NEWS_EDIT_TITLE                 : "Editar novedad",
    NEWS_DELETE_TITLE               : "Eliminar novedad",
    NEWS_DELETE_TEXT                : "¿Realmente desea eliminar la novedad <b>{0}</b>?",
    NEWS_TITLE                      : "Título de la novedad",
    NEWS_TEXT                       : "Texto de la novedad",
    NEWS_NONE_AVAILABLE             : "No hay novedades disponibles.",
    NEWS_ERROR_EXISTS               : "No existe la novedad indicada.",
    NEWS_ERROR_TITLE                : "Debe indicar el título de la novedad.",
    NEWS_ERROR_TEXT                 : "Debe indicar el texto de la novedad.",
    NEWS_ERROR_STATUS               : "Debe indicar el estado de la novedad.",
    NEWS_SUCCESS_CREATE             : "La novedad se creó correctamente.",
    NEWS_SUCCESS_EDIT               : "La novedad se editó correctamente.",
    NEWS_SUCCESS_DELETE             : "La novedad se eliminó correctamente.",

    // Setup: Advices
    ADVICES_NAME                    : "Consejos",
    ADVICES_SINGULAR                : "Consejo",
    ADVICES_CREATE_TITLE            : "Crear consejo",
    ADVICES_EDIT_TITLE              : "Editar consejo",
    ADVICES_DELETE_TITLE            : "Eliminar consejo",
    ADVICES_DELETE_TEXT             : "¿Realmente desea eliminar el consejo <b>{0}</b>?",
    ADVICES_ITS_NAME                : "Nombre del consejo",
    ADVICES_MESSAGE                 : "Mensaje del consejo",
    ADVICES_IMAGE                   : "Imagen del consejo",
    ADVICES_NONE_AVAILABLE          : "No hay consejos disponibles.",
    ADVICES_ERROR_EXISTS            : "No existe el consejo indicado.",
    ADVICES_ERROR_NAME              : "Debe indicar el nombre del consejo.",
    ADVICES_ERROR_IMAGE             : "Debe indicar la imagen del consejo.",
    ADVICES_ERROR_STATUS            : "Debe indicar el estado del consejo.",
    ADVICES_SUCCESS_CREATE          : "El consejo se creó correctamente.",
    ADVICES_SUCCESS_EDIT            : "El consejo se editó correctamente.",
    ADVICES_SUCCESS_DELETE          : "El consejo se eliminó correctamente.",

    // Setup: Payment Methods
    PAYMENT_METHODS_NAME            : "Métodos de pago",
    PAYMENT_METHODS_SINGULAR        : "Método de pago",
    PAYMENT_METHODS_CREATE_TITLE    : "Crear método de pago",
    PAYMENT_METHODS_EDIT_TITLE      : "Editar método de pago",
    PAYMENT_METHODS_DELETE_TITLE    : "Eliminar método de pago",
    PAYMENT_METHODS_DELETE_TEXT     : "¿Realmente desea eliminar el método de pago <b>{0}</b>?",
    PAYMENT_METHODS_ITS_NAME        : "Nombre del método de pago",
    PAYMENT_METHODS_NONE_AVAILABLE  : "No hay métodos de pago disponibles.",
    PAYMENT_METHODS_ERROR_EXISTS    : "No existe el método de pago indicado.",
    PAYMENT_METHODS_ERROR_NAME      : "Debe indicar el nombre del método de pago.",
    PAYMENT_METHODS_ERROR_STATUS    : "Debe indicar el estado del método de pago.",
    PAYMENT_METHODS_SUCCESS_CREATE  : "El método de pago se creó correctamente.",
    PAYMENT_METHODS_SUCCESS_EDIT    : "El método de pago se editó correctamente.",
    PAYMENT_METHODS_SUCCESS_DELETE  : "El método de pago se eliminó correctamente.",

    // Setup: Material Categories
    MAT_CATEGORIES_NAME             : "Categorías de materiales",
    MAT_CATEGORIES_SINGULAR         : "Categoría de material",
    MAT_CATEGORIES_CREATE_TITLE     : "Crear categoría de material",
    MAT_CATEGORIES_EDIT_TITLE       : "Editar categoría de material",
    MAT_CATEGORIES_DELETE_TITLE     : "Eliminar categoría de material",
    MAT_CATEGORIES_DELETE_TEXT      : "¿Realmente desea eliminar la categoría de material <b>{0}</b>?",
    MAT_CATEGORIES_ITS_NAME         : "Nombre de la categoría de material",
    MAT_CATEGORIES_NONE_AVAILABLE   : "No hay categorías de materiales disponibles.",
    MAT_CATEGORIES_ERROR_EXISTS     : "No existe la categoría de material indicada.",
    MAT_CATEGORIES_ERROR_NAME       : "Debe indicar el nombre de la categoría de material.",
    MAT_CATEGORIES_ERROR_STATUS     : "Debe indicar el estado de la categoría de material.",
    MAT_CATEGORIES_SUCCESS_CREATE   : "La categoría de material se creó correctamente.",
    MAT_CATEGORIES_SUCCESS_EDIT     : "La categoría de material se editó correctamente.",
    MAT_CATEGORIES_SUCCESS_DELETE   : "La categoría de material se eliminó correctamente.",

    // Setup: Countries
    COUNTRIES_NAME                  : "Países",
    COUNTRIES_SINGULAR              : "País",
    COUNTRIES_CREATE_TITLE          : "Crear país",
    COUNTRIES_EDIT_TITLE            : "Editar país",
    COUNTRIES_DELETE_TITLE          : "Eliminar país",
    COUNTRIES_DELETE_TEXT           : "¿Realmente desea eliminar el país <b>{0}</b>?",
    COUNTRIES_ITS_NAME              : "Nombre del país",
    COUNTRIES_CODE                  : "Código ISO del país",
    COUNTRIES_IDD                   : "Característica para celulares",
    COUNTRIES_IDD_SHORT             : "Característica",
    COUNTRIES_NONE_AVAILABLE        : "No hay países disponibles.",
    COUNTRIES_ERROR_EXISTS          : "No existe el país indicada.",
    COUNTRIES_ERROR_NAME            : "Debe indicar el nombre del país.",
    COUNTRIES_ERROR_CODE_EMPTY      : "Debe indicar el código del país.",
    COUNTRIES_ERROR_CODE_EXISTS     : "Ya existe otro país con el código indicado.",
    COUNTRIES_ERROR_IDD_EMPTY       : "Debe indicar la característica del país.",
    COUNTRIES_ERROR_IDD_NUMBER      : "La característica debe ser un numéro mayor a 0.",
    COUNTRIES_ERROR_IDD_EXISTS      : "Ya existe otro país con la ccaracterística indicada.",
    COUNTRIES_ERROR_STATUS          : "Debe indicar el estado del país.",
    COUNTRIES_SUCCESS_CREATE        : "El país se creó correctamente.",
    COUNTRIES_SUCCESS_EDIT          : "El país se editó correctamente.",
    COUNTRIES_SUCCESS_DELETE        : "El país se eliminó correctamente.",

    // Setup: Provinces
    PROVINCES_NAME                  : "Provincias",
    PROVINCES_SINGULAR              : "Provincia",
    PROVINCES_CREATE_TITLE          : "Crear provincia",
    PROVINCES_EDIT_TITLE            : "Editar provincia",
    PROVINCES_DELETE_TITLE          : "Eliminar provincia",
    PROVINCES_DELETE_TEXT           : "¿Realmente desea eliminar la provincia <b>{0}</b>?",
    PROVINCES_COUNTRY               : "País de la provincia",
    PROVINCES_ITS_NAME              : "Nombre de la provincia",
    PROVINCES_CODE                  : "Código de la provincia",
    PROVINCES_NONE_AVAILABLE        : "No hay provincias disponibles.",
    PROVINCES_ERROR_EXISTS          : "No existe la provincia indicada.",
    PROVINCES_ERROR_COUNTRY         : "Debe indicar el país de la provincia.",
    PROVINCES_ERROR_NAME            : "Debe indicar el nombre de la provincia.",
    PROVINCES_ERROR_CODE_EMPTY      : "Debe indicar el código de la provincia.",
    PROVINCES_ERROR_CODE_EXISTS     : "Ya existe otra provincia con el código indicado.",
    PROVINCES_ERROR_STATUS          : "Debe indicar el estado de la provincia.",
    PROVINCES_SUCCESS_CREATE        : "La provincia se creó correctamente.",
    PROVINCES_SUCCESS_EDIT          : "La provincia se editó correctamente.",
    PROVINCES_SUCCESS_DELETE        : "La provincia se eliminó correctamente.",

    // Setup: Localities
    LOCALITIES_NAME                 : "Localidades",
    LOCALITIES_SINGULAR             : "Localidad",
    LOCALITIES_CREATE_TITLE         : "Crear localidad",
    LOCALITIES_EDIT_TITLE           : "Editar localidad",
    LOCALITIES_DELETE_TITLE         : "Eliminar localidad",
    LOCALITIES_DELETE_TEXT          : "¿Realmente desea eliminar la localidad <b>{0}</b>?",
    LOCALITIES_COUNTRY              : "País de la localidad",
    LOCALITIES_PROVINCE             : "Provincia de la localidad",
    LOCALITIES_ITS_NAME             : "Nombre de la localidad",
    LOCALITIES_ITS_DEPARTMENT       : "Departamento de la localidad",
    LOCALITIES_DEPARTMENT           : "Departamento",
    LOCALITIES_NONE_AVAILABLE       : "No hay localidades disponibles.",
    LOCALITIES_ERROR_EXISTS         : "No existe la localidad indicada.",
    LOCALITIES_ERROR_NAME           : "Debe indicar el nombre de la localidad.",
    LOCALITIES_ERROR_COUNTRY        : "Debe indicar el país de la localidad.",
    LOCALITIES_ERROR_PROVINCE       : "Debe indicar la provincia de la localidad.",
    LOCALITIES_ERROR_STATUS         : "Debe indicar el estado de la localidad.",
    LOCALITIES_SUCCESS_CREATE       : "La localidad se creó correctamente.",
    LOCALITIES_SUCCESS_EDIT         : "La localidad se editó correctamente.",
    LOCALITIES_SUCCESS_DELETE       : "La localidad se eliminó correctamente.",

    // Setup: Email Templates
    EMAIL_TEMPLATES_NAME            : "Plantillas de emails",
    EMAIL_TEMPLATES_TITLE           : "Editar plantilla de emails",
    EMAIL_TEMPLATES_SEND_AS         : "Enviar desde el mail",
    EMAIL_TEMPLATES_SEND_NAME       : "Enviar con el nombre",
    EMAIL_TEMPLATES_SEND_TO         : "Enviar a",
    EMAIL_TEMPLATES_SEND_TO_HELP    : "Indique los emails a quien se envía el email. Si se deja en blanco se envía a los administradores.",
    EMAIL_TEMPLATES_MESSAGE         : "Mensaje del email",
    EMAIL_TEMPLATES_NONE_AVAILABLE  : "No hay plantillas de emails disponibles.",
    EMAIL_TEMPLATES_ERROR_EXISTS    : "No existe la plantilla de email indicada.",
    EMAIL_TEMPLATES_ERROR_SEND_AS   : "Debe indicar el email de quien lo envia.",
    EMAIL_TEMPLATES_ERROR_SUBJECT   : "Debe indicar el asunto del email.",
    EMAIL_TEMPLATES_ERROR_MESSAGE   : "Debe indicar el mensaje del email.",
    EMAIL_TEMPLATES_ERROR_TYPE      : "No existe el tipo de email indicado.",
    EMAIL_TEMPLATES_SUCCESS_EDIT    : "La plantilla de emails se editó correctamente.",

    // Setup: Email Queue
    EMAIL_QUEUE_NAME                : "Cola de emails",
    EMAIL_QUEUE_TITLE               : "Ver email",
    EMAIL_QUEUE_SEND_AS             : "Enviar desde el mail",
    EMAIL_QUEUE_SEND_NAME           : "Enviar con el nombre",
    EMAIL_QUEUE_SEND_TO             : "Enviar a",
    EMAIL_QUEUE_SEND_DATE           : "Procesado el",
    EMAIL_QUEUE_MESSAGE             : "Mensaje del email",
    EMAIL_QUEUE_NONE_AVAILABLE      : "No hay cola de emails disponibles.",

    // Setup: Email White List
    EMAIL_WHITE_LIST_NAME           : "Lista blanca de emails",
    EMAIL_WHITE_LIST_CREATE_TITLE   : "Agregar email",
    EMAIL_WHITE_LIST_EDIT_TITLE     : "Editar email",
    EMAIL_WHITE_LIST_DELETE_TITLE   : "Quitar email",
    EMAIL_WHITE_LIST_DELETE_TEXT    : "¿Realmente desea quitar el email <b>{0}</b>?",
    EMAIL_WHITE_LIST_EMAIL          : "Email disponible para envíos",
    EMAIL_WHITE_LIST_DESCRIPTION    : "Descripción sobre el email",
    EMAIL_WHITE_LIST_NONE_AVAILABLE : "No hay emails disponibles.",
    EMAIL_WHITE_LIST_ERROR_EXISTS   : "No existe la plantilla de email indicada.",
    EMAIL_WHITE_LIST_ERROR_EMPTY    : "Debe indicar el email a agregar.",
    EMAIL_WHITE_LIST_ERROR_INVALID  : "El email indicado es incorrecto.",
    EMAIL_WHITE_LIST_ERROR_USED     : "El email indicado ya esta agregado.",
    EMAIL_WHITE_LIST_SUCCESS_ADD    : "El email se agregó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_EDIT   : "El email se editó correctamente.",
    EMAIL_WHITE_LIST_SUCCESS_REMOVE : "El email se quitó correctamente.",

    // Setup: Actions Log
    ACTIONS_LOG_NAME                : "Acciones",
    ACTIONS_LOG_LIST                : "Log de acciones",
    ACTIONS_LOG_SESSION             : "Sesión",
    ACTIONS_LOG_ACTION              : "Acción",
    ACTIONS_LOG_SECTION             : "Sección",
    ACTIONS_LOG_NONE_AVAILABLE      : "No hay acciones disponibles.",

    // Setup: Errors Log
    ERRORS_LOG_NAME                 : "Errores",
    ERRORS_LOG_SINGULAR             : "Error",
    ERRORS_LOG_LIST                 : "Log de errores",
    ERRORS_LOG_VIEW_TITLE           : "Ver error",
    ERRORS_LOG_NONE_AVAILABLE       : "No hay errores disponibles.",
    ERRORS_LOG_AMOUNT               : "Cantidad",
    ERRORS_LOG_RESOLVE              : "Marcar como resuelto",
    ERRORS_LOG_RESOLVED             : "Resuelto",
    ERRORS_LOG_ERROR_EXISTS         : "No existe el error indicado.",
    ERRORS_LOG_SUCCESS_RESOLVED     : "El error se marcó como resuelto correctamente.",



    // Profile: Profile
    ACCOUNT_NAME                    : "Cuenta",
    ACCOUNT_MINE                    : "Mi cuenta",
    ACCOUNT_CHANGE_OPTIONS          : "Cambiar opciones",
    ACCOUNT_CHANGE_PASSWORD         : "Cambiar contraseña",
    ACCOUNT_CHANGE_AVATAR           : "Cambiar foto",
    ACCOUNT_ERROR_FIRST_NAME        : "Debe indicar su nombre.",
    ACCOUNT_ERROR_LAST_NAME         : "Debe indicar su apellido.",
    ACCOUNT_ERROR_EMAIL_EMPTY       : "Debe indicar su email.",
    ACCOUNT_ERROR_EMAIL_INVALID     : "El email es incorrecto o esta usado por otro usuario.",
    ACCOUNT_ERROR_LANGUAGE_EMPTY    : "Debe indicar su idioma.",
    ACCOUNT_ERROR_LANGUAGE_INVALID  : "El idioma indicado es incorrecto.",
    ACCOUNT_ERROR_AVATAR_EMPTY      : "Debe proporcionar una image.",
    ACCOUNT_ERROR_AVATAR_SIZE       : "La imagen es muy grande, intente con una más pequeña.",
    ACCOUNT_ERROR_AVATAR_TYPE       : "El archivo provisto no es una imagen.",
    ACCOUNT_SUCCESS_EDIT            : "La cuenta fue editada correctamente.",
    ACCOUNT_SUCCESS_UPLOAD          : "La foto se subió correctamente.",



    // Media Dialog
    MEDIA_NAME                      : "Archivos",
    MEDIA_SELECT_TITLE              : "Seleccionar archivo",
    MEDIA_CREATE_TITLE              : "Crear directorio",
    MEDIA_EDIT_DIR_TITLE            : "Renombrar directorio",
    MEDIA_EDIT_FILE_TITLE           : "Renombrar archivo",
    MEDIA_DELETE_DIR_TITLE          : "Eliminar directorio",
    MEDIA_DELETE_FILE_TITLE         : "Eliminar archivo",
    MEDIA_DELETE_DIR_TEXT           : "¿Realmente desea eliminar el directorio <b>{0}</b>?",
    MEDIA_DELETE_FILE_TEXT          : "¿Realmente desea eliminar el archivo <b>{0}</b>?",
    MEDIA_NONE_AVAILABLE            : "No hay archivos disponibles",
    MEDIA_ERROR_CREATE              : "No fue posible crear el directorio indicado.",
    MEDIA_ERROR_CREATE_NAME         : "Debe indicar el nombre del directorio.",
    MEDIA_ERROR_CREATE_EXISTS       : "Ya existe un archivo/directorio con ese nombre.",
    MEDIA_ERROR_UPLOAD              : "Ocurrió al subir el archivo.",
    MEDIA_ERROR_UPLOAD_EMPTY        : "Debe indicar un archivo para subir.",
    MEDIA_ERROR_UPLOAD_SIZE         : "El archivo es demasiado grande (Supera los 4mb).",
    MEDIA_ERROR_UPLOAD_EXISTS       : "Ya existe un archivo con ese nombre.",
    MEDIA_ERROR_RENAME              : "Ocurrió un error al renombrar el archivo/directorio indicado.",
    MEDIA_ERROR_RENAME_OLD_NAME     : "Debe indicar el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_NAME     : "Debe indicar el nuevo nombre del archivo.",
    MEDIA_ERROR_RENAME_OLD_EXISTS   : "No existe el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_EXISTS   : "Ya existe un archivo/directorio con el nuevo nombre.",
    MEDIA_ERROR_MOVE                : "Ocurrió un error al mover el archivo indicado.",
    MEDIA_ERROR_MOVE_OLD_PATH       : "Debe indicar el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_PATH       : "Debe indicar el nuevo directorio del archivo.",
    MEDIA_ERROR_MOVE_OLD_EXISTS     : "No existe el archivo para mover.",
    MEDIA_ERROR_MOVE_NEW_EXISTS     : "Ya existe un archivo con el mismo nombre en el directorio indicado.",
    MEDIA_ERROR_DELETE              : "Ocurrió un error al eliminar el archivo/directorio indicado.",
    MEDIA_ERROR_DELETE_NAME         : "Debe indicar el archivo/directorio para eliminar.",
    MEDIA_ERROR_DELETE_EXISTS       : "No existe el archivo/directorio para eliminar.",
    MEDIA_SUCCESS_CREATE            : "El directorio se creó correctamente.",
    MEDIA_SUCCESS_UPLOAD            : "El archivo se subió correctamente.",
    MEDIA_SUCCESS_RENAME            : "El archivo/directorio se renombró correctamente.",
    MEDIA_SUCCESS_MOVE              : "El archivo se movió correctamente.",
    MEDIA_SUCCESS_DELETE            : "El archivo/directorio se eliminó correctamente.",

    // DropZone
    DROPZONE_FILES_TITLE             : "Arrastra archivos a cualquier lugar para subirlos",
    DROPZONE_IMAGES_TITLE            : "Arrastra imágenes a cualquier lugar para subirlas",
    DROPZONE_OR                      : "ó",
    DROPZONE_FILES_BUTTON            : "Seleccionar archivos",
    DROPZONE_IMAGES_BUTTON           : "Seleccionar imágenes",
    DROPZONE_FILES_DROP              : "Arrastra los archivos aquí para subirlos",
    DROPZONE_IMAGES_DROP             : "Arrastra las imágenes aquí para subirlas",

    // Access
    ACCESS_STUDENT                  : "Alumno",
    ACCESS_COACH                    : "Coach",
    ACCESS_SUPPORT                  : "Soporte",
    ACCESS_ADMIN                    : "Administrador",
    ACCESS_OWNER                    : "Dueño",

    // Status
    STATUS_ACTIVE                   : "Activo",
    STATUS_ACTIVE_FEM               : "Activa",
    STATUS_INACTIVE                 : "Inactivo",
    STATUS_INACTIVE_FEM             : "Inactiva",
    STATUS_APPROVED                 : "Aprobado",
    STATUS_APPROVED_FEM             : "Aprobada",
    STATUS_CANCELLED                : "Cancelado",
    STATUS_CANCELLED_FEM            : "Cancelada",
    STATUS_PAUSED                   : "Pausado",
    STATUS_PAUSED_FEM               : "Pausada",
    STATUS_PENDING                  : "Pendiente",
    STATUS_PENDING_FEM              : "Pendiente",
    STATUS_DRAFT                    : "Borrador",
    STATUS_DRAFT_FEM                : "Borrador",
    STATUS_COMPLETED                : "Completado",
    STATUS_COMPLETED_FEM            : "Completada",
    STATUS_REPORTED                 : "Reportado",
    STATUS_REPORTED_FEM             : "Reportada",
    STATUS_OPEN                     : "Abierto",
    STATUS_OPEN_FEM                 : "Abierta",
    STATUS_CLOSED                   : "Cerrado",
    STATUS_CLOSED_FEM               : "Cerrada",
    STATUS_RECEIVED                 : "Recibido",
    STATUS_RECEIVED_FEM             : "Recibida",
    STATUS_RESPONDED                : "Respondido",
    STATUS_RESPONDED_FEM            : "Respondida",
    STATUS_INTERESTED               : "Interesado",
    STATUS_INTERESTED_FEM           : "Interesada",
    STATUS_ABANDONED                : "Abandonado",
    STATUS_ABANDONED_FEM            : "Abandonada",
    STATUS_POTENTIAL                : "Potencial",
    STATUS_POTENTIAL_FEM            : "Potencial",
    STATUS_SENT                     : "Enviado",
    STATUS_SENT_FEM                 : "Enviada",
    STATUS_PAID                     : "Pagado",
    STATUS_PAID_FEM                 : "Pagada",
    STATUS_REJECTED                 : "Rechazado",
    STATUS_REJECTED_FEM             : "Rechazada",
    STATUS_ASSIGNED                 : "Asignado",
    STATUS_ASSIGNED_FEM             : "Asignada",
    STATUS_NREPORTED                : "No Reportado",
    STATUS_NREPORTED_FEM            : "No Reportada",
    STATUS_REQUESTED                : "Solicitado",
    STATUS_REQUESTED_FEM            : "Solicitada",
    STATUS_SCHEDULED                : "Programado",
    STATUS_SCHEDULED_FEM            : "Programada",
    STATUS_EXPIRED                  : "Vencido",
    STATUS_EXPIRED_FEM              : "Vencida",

    // Periods
    PERIOD_SELECT                   : "Seleccionar periodo",
    PERIOD_LAST_7_DAYS              : "Últimos 7 días",
    PERIOD_LAST_15_DAYS             : "Últimos 15 días",
    PERIOD_LAST_30_DAYS             : "Últimos 30 días",
    PERIOD_LAST_60_DAYS             : "Últimos 60 días",
    PERIOD_LAST_90_DAYS             : "Últimos 90 días",
    PERIOD_LAST_YEAR                : "Último año",
    PERIOD_THIS_WEEK                : "Esta semana",
    PERIOD_THIS_MONTH               : "Este mes",
    PERIOD_THIS_YEAR                : "Este año",
    PERIOD_PAST_WEEK                : "La semana pasada",
    PERIOD_PAST_MONTH               : "El mes pasado",
    PERIOD_PAST_YEAR                : "El año pasado",
    PERIOD_ALL_PERIOD               : "Todo el periodo",
    PERIOD_CUSTOM                   : "Personalizado",

    // Replazable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                  : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DATE_DAY_SHORTS                 : [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá" ],
    DATE_MONTH_NAMES                : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],

    DATE_TIME                       : "{h}:{i}",
    DATE_DAY_TIME                   : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                  : "{d} de {mn}",
    DATE_DAY_MONTH_SHORT            : "{d}, {m3}",
    DATE_DAY_MONTH_MEDIUM           : "{d} de {m3}",
    DATE_DAY_MONTH_YEAR             : "{d} de {mn}, {y}",
    DATE_DAY_YEAR_SHORT             : "{d}, {m3} {y}",
    DATE_DAY_YEAR_MEDIUM            : "{d} de {m3} {y}",
    DATE_MONTH_YEAR                 : "{mn} {y}",
    DATE_REDUCED                    : "{dn} {d0}/{m0}",
    DATE_COMPLETE                   : "{dn}, {d} de {mn}, {y}",
    DATE_SHORT_COMPLETE             : "{d3}, {d} de {m3}, {y}",
    DATE_DASHES                     : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE             : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_DASHES_HOUR                : "{d0}-{m0}-{y}, {h}:{i}",
    DATE_SLASHES                    : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                : "{d0}/{m0}",
    DATE_SORTABLE                   : "{y}/{m0}/{d0}",

    DATE_MIN_AGO                    : "{i} minuto atras",
    DATE_MINS_AGO                   : "{i} minutos atras",
    DATE_HOUR_AGO                   : "{h} hora atras",
    DATE_HOURS_AGO                  : "{h} horas atras",
    DATE_DAY_AGO                    : "{h} día atras",
    DATE_DAYS_AGO                   : "{h} días atras",
    DATE_IN_MIN                     : "En {i} minuto",
    DATE_IN_MINS                    : "En {i} minutos",
    DATE_IN_HOUR                    : "En {h} hora",
    DATE_IN_HOURS                   : "En {h} horas",
    DATE_IN_DAY                     : "En {h} día",
    DATE_IN_DAYS                    : "En {h} días",
    DATE_TOMORROW_AT                : "Mañana a las {h}:{i}",
    DATE_TODAY_AT                   : "Hoy a las {h}:{i}",
    DATE_YESTERDAY_AT               : "Ayer a las {h}:{i}",
    DATE_THIS_WEEK_AT               : "{dn} a las {h}:{i}",
    DATE_THIS_YEAR_AT               : "{d} de {m3} a las {h}:{i}",
    DATE_OTHER_YEAR_AT              : "{d} de {m3} {y} a las {h}:{i}",

    DATE_YESTERDAY                  : "Ayer",
    DATE_TODAY                      : "Hoy",
    DATE_TOMORROW                   : "Mañana",
    DATE_LAST_WEEK                  : "La Semana Pasada",
    DATE_THIS_WEEK                  : "Esta Semana",
    DATE_NEXT_WEEK                  : "La Semana Próxima",
    DATE_PARSED_WEEK                : "Semana del {0} al {1}",
    DATE_DURATION                   : "{0} - {1}",

};

export default data;
